import { Component, OnInit } from '@angular/core';
import {ChallengeService} from "../../services/challenge.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DomSanitizer} from "@angular/platform-browser";
import {PopupHelperService} from "../../services/popup-helper.service";
import {SocketMainService} from "../../services/socket/socket-main-service";
import {appConstants} from "../../constants";

@Component({
  selector: 'app-game-battle',
  templateUrl: './game-battle.component.html',
  styleUrls: ['./game-battle.component.scss']
})
export class GameBattleComponent implements OnInit {

  constructor(private challengeService: ChallengeService,
              private route: ActivatedRoute,
              private domSanitizer: DomSanitizer,
              private popupHelperService: PopupHelperService,
              private router: Router,
              private socketMainService: SocketMainService) { }

  public event: any
  public eventId: any
  public participant: any
  public openUrl: any
  public forTable: any
  ngOnInit(): void {
    this.forTable =  this.route.snapshot.paramMap.get('forTable');
    this.eventId = this.route.snapshot.paramMap.get('forId');
    if(this.forTable === 'challenge'){
      this.challengeService.getById(this.eventId).subscribe((data: any)=>{
        if(!data.hasError){
          this.event = data.data.challenge
          if(this.event.status === "finished"){
            this.popupHelperService.printError({text: 'challengeStatusIsFinished'}, this.router.navigate(['/']))
          }else {
            this.participant = data.data.participant;
            this.openUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.participant.redirectUrl)
          }
        }
      })
    }

    this.socketMainService.listenToEventWithCallback(appConstants.ACTIONTYPES.UPDATE, appConstants.EVENTTYPES.USER, (data: any)=>{
      if(data.type === appConstants.EVENTTYPES.PARTICIPANT && data.data.forId._id === this.eventId){
        let currenAction = data.data.actionsForUser[data.data.currentActionIndex]
        if(currenAction && currenAction.action === "disputePeriod"){
          this.router.navigate(['/tournament/challenge', data.data.forId._id])
        }
      }
    })
  }

}
