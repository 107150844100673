import { Injectable , EventEmitter} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpService} from "./http.service";
import {Observable} from "rxjs";

@Injectable()
export class WallService {
  private list = '/wall';
  private postList = '/posts/list';
  private seenPostsUrl = '/wall/seen';

  private addPostUrl= '/post/add';
  private updatePostUrl= '/post/:id';
  private blockPostUrl= '/post/block/:id';
  private unblockPostUrl= '/post/unblock/:id';
  private likePostUrl = '/post/like/:id';
  private dislikePostUrl = '/post/dislike/:id';
  private sharePostUrl = '/post/share/:id';
  constructor(private networkHelper: HttpService) { }

  getWall(limit = 6): Observable<any> {
    return this.networkHelper.get<any>(environment.apiURL + this.list, {limit});
  }

  getItems(pagination = {}, filter = {}): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.postList, {...pagination, ...filter});
  }

  seenPosts(postIds = []): Observable<any>{
    return this.networkHelper.post<any>(environment.apiURL + this.seenPostsUrl, { postIds });
  }



  add(data : any): Observable<any> {
    return this.networkHelper.post(environment.apiURL +
      this.addPostUrl, data);
  }

  delete(id: any): Observable<any> {
    return this.networkHelper.del(environment.apiURL +
      this.updatePostUrl.replace(':id', id));
  }

  getById(id: any): Observable<any> {
    return this.networkHelper.get(environment.apiURL +
      this.updatePostUrl.replace(':id', id));
  }

  update(id: any, data: any): Observable<any> {
    return this.networkHelper.put(environment.apiURL +
      this.updatePostUrl.replace(':id',id), data);
  }

  like(id: any): Observable<any> {
    return this.networkHelper.put(environment.apiURL + this.likePostUrl.replace(':id',id), {});
  }

  dislike(id: any): Observable<any> {
    return this.networkHelper.put(environment.apiURL + this.dislikePostUrl.replace(':id',id), {});
  }

  block(id: any): Observable<any> {
    return this.networkHelper.put(environment.apiURL + this.blockPostUrl.replace(':id',id), {});
  }

  unblock(id: any): Observable<any> {
    return this.networkHelper.put(environment.apiURL + this.unblockPostUrl.replace(':id',id), {});
  }
  share(id: string): Observable<any> {
    return this.networkHelper.post(environment.apiURL + this.sharePostUrl.replace(':id',id), {});
  }



}
