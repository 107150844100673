import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import {StorageService} from '../services/storage.service';
import { MeService } from '../services/me.service';
import {WindowRef} from "../helpers/windowRef.service";
import {Observable} from "rxjs";
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router,
              private meService:MeService,
              @Inject(PLATFORM_ID) private platformId: any,
              private windowRef: WindowRef) {
    StorageService.platformId = platformId;
    StorageService.windowRef = windowRef;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = StorageService.getItem(StorageService.statics.token);
    let url = (isPlatformBrowser(this.platformId))?this.windowRef.nativeWindow.location.pathname.toString():"";
    if (!token) {
      this.router.navigate([url]);
      return false;
    }
    let currentUser = StorageService.getJsonItem(StorageService.statics.currentUser);
    if(!currentUser){
      StorageService.removeItem(StorageService.statics.token);
      StorageService.removeItem(StorageService.statics.profileData);
      this.router.navigate([url]);
      return false;
    } else if(currentUser.status === 'blocked' || currentUser.status === 'inactive' || currentUser.status === 'deleted'){
      StorageService.removeItem(StorageService.statics.token);
      StorageService.removeItem(StorageService.statics.profileData);
      alert('your account was not active. Contact support')
      return false;
    }

    return true;
  }
}
