import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HttpService} from "./http.service";
import {environment} from "../../environments/environment";

@Injectable()
export class ChallengeTemplateService {

  private getByIdUrl = '/challengeTemplate/:id';
  private getListUrl = '/challengeTemplate/list';

  ////

  constructor(private networkHelper: HttpService) {
  }


  getById(id: any): Observable<any> {
    return this.networkHelper.get(environment.apiURL +
      this.getByIdUrl.replace(':id', id));
  }

  getList(filter:any){
    return this.networkHelper.get(environment.apiURL +
      this.getListUrl, filter);
  }
}
