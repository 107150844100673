import { Injectable } from '@angular/core';
import {HttpService} from './http.service';
import {environment} from '../../environments/environment';
import {Observable} from "rxjs";

@Injectable()
export class AccountTaskService {

  constructor(private networkHelper: HttpService) { }

  private getTaskRewardUrl = '/task-management/take-reward/:id';
  private rejectUrl = '/task-management/reject/:id';
  private rejectTaskUrl = '/me/reject-task/{{id}}';
  private acceptUrl = '/task-management/accept/:id';
  private getTaskListUrl = '/task-management/user/list';

  private getFirstTaskUrl = '/task-management/user/list/first';

  private getTaskByKeyUrl = '/task-management/user/task/:key';


  getReward(id: string,data: object){
    return this.networkHelper.put<any>(environment.apiURL + this.getTaskRewardUrl.replace(':id', id), data);
  }
  rejectTask(id:any): Observable<any> {
    return this.networkHelper.put(environment.apiURL +
      this.rejectTaskUrl.replace('{{id}}', id), {});
  }
  rejectTemplate(id:any): Observable<any> {
    return this.networkHelper.put(environment.apiURL +
      this.rejectUrl.replace(':id', id), {});
  }
  accept(id: string, data: any): Observable<any> {
    return this.networkHelper.put(environment.apiURL +
      this.acceptUrl.replace(':id',id), data);
  }
  getTaskList():any{
    return this.networkHelper.get<any>(environment.apiURL + this.getTaskListUrl);
  }

  getDoneFirstTask(){
    return this.networkHelper.get<any>(environment.apiURL + this.getFirstTaskUrl);
  }

  getTaskByKey(key: string){
    return this.networkHelper.get<any>(environment.apiURL + this.getTaskByKeyUrl.replace(':key', key));
  }
}
