import { Injectable } from '@angular/core';
import {HttpService} from './http.service';
import {Observable} from 'rxjs';
import {environment} from "../../environments/environment";
@Injectable()
export class QualityService {

  private addFeedBackUrl = '/quality/feedback';
  private feedBackListUrl = '/quality/feedback/list';
  private getMyFeedBackUrl = '/quality/myFeedback/:forId/:forTable';

  ////

  constructor(private networkHelper: HttpService) {
  }

  addFeedBack(data: any): Observable<any> {
    return this.networkHelper.post(environment.apiURL + this.addFeedBackUrl, data);
  }

  deleteFeedBack(id: any): Observable<any> {
    return this.networkHelper.del(environment.apiURL + this.addFeedBackUrl + '/'+id);
  }

  getMyFeedBack(forId: string, forTable: string): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.getMyFeedBackUrl.replace(':forId', forId).replace(':forTable', forTable));
  }

  list(forId: string, forTable: string, pagination:any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.feedBackListUrl,{forTable, forId, ...pagination});
  }

}



