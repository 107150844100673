import {Pipe, PipeTransform} from '@angular/core';
import {appConstants} from "../../../constants";

@Pipe({
  name: 'scoreSum'
})
export class ScoreSumPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let sum = 0;
    for(let key in value){
      sum = sum + value[key].value;
    }
    return sum
  }

}
