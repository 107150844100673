import { Injectable } from '@angular/core';
import {HttpService} from '../http.service';
import {environment} from '../../../environments/environment';

@Injectable()
export class PublisherSettingsService {

  constructor(private networkHelper: HttpService) { }

  private getUrl = '/publisher-settings';
  private updateUrl = '/publisher-settings/update';
  private goLiveUrl = '/publisher-settings/go-live'
  private accountsUrl = '/publisher-settings/accounts'
  private deleteAccountUrl = '/publisher-settings/account/:id/:publisherId'
  private addAccountUrl = '/publisher-settings/account/accept/:id'
  private removeMeUrl = '/publisher-settings/remove-me'
  private acceptAccountUrl = '/publisher-settings/account/accept/:id'

  private inviteAccountUrl = '/publisher-settings/invite'
  private sendAnnouncementUrl = '/publisher-settings/create-announcement/:forTable/:forId'
  private sendAnnouncementUrlGlobe = '/publisher-settings/create-announcement'

  getPublisher(id: string): any {
   return this.networkHelper.get<any>(environment.apiURL + this.getUrl + '?publisherId=' + id);
  }

  update(data: any): any {
    return this.networkHelper.put<any>(environment.apiURL + this.updateUrl, data);
  }

  delete(id:any): any {
    return this.networkHelper.del<any>(environment.apiURL + this.getUrl+ '?publisherId=' + id);
  }

  goLivePublisher(publisherId:any): any {
    return this.networkHelper.put<any>(environment.apiURL + this.goLiveUrl, {publisherId});
  }

  getAccounts(id:any): any {
    return this.networkHelper.get<any>(environment.apiURL + this.accountsUrl, {id});
  }

  deleteAccount(id:any, publisherId: any): any {
    return this.networkHelper.del<any>(environment.apiURL + this.deleteAccountUrl.replace(":id", id).replace(":publisherId", publisherId));
  }

  addAccount(publisherId: string, userId: string, role: string): any {
    return this.networkHelper.put<any>(environment.apiURL + this.inviteAccountUrl, {publisherId, userId, role});
  }

  removeMe(): any {
    return this.networkHelper.put<any>(environment.apiURL + this.removeMeUrl, {});
  }

  acceptAccount(id:any, data:any): any {
    return this.networkHelper.put<any>(environment.apiURL + this.acceptAccountUrl.replace(':id', id), data);
  }

  cancelInvitation(id:any, publisherId: any){
    return this.networkHelper.del<any>(environment.apiURL + this.deleteAccountUrl.replace(":id", id).replace(':publisherId', publisherId));
  }


  sendAnnouncementGlobe(message:any, publisherId: any){
    return this.networkHelper.post<any>(environment.apiURL + this.sendAnnouncementUrlGlobe, {message, publisherId});
  }
  sendAnnouncementEvent(forTable:any,forId:any, message:any, publisherId: any){
    return this.networkHelper.post<any>(environment.apiURL + this.sendAnnouncementUrl.replace(":forTable", forTable).replace(':forId', forId), {message, publisherId});
  }
}
