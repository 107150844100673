import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-primary-button',
  templateUrl: './primary-button.component.html',
  styleUrls: ['./primary-button.component.scss']
})
export class PrimaryButtonComponent implements OnInit {

  @Input() text: string = '';
  @Input() link: any = null;
  @Input() url: string = '';
  @Input() icon: string = '';
  @Input() target: any=null;

  @Input() className: string = '';

  @Input() id: string = '';
  @Input() style: any;
  @Input() fragment: any;
  @Input() load: boolean = false;
  @Input() disabled: boolean = false;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick: EventEmitter<any> = new EventEmitter();

  constructor( private router: Router) { }

  ngOnInit(): void {
    if(!this.id){
      this.id = this.text.replace(' ', '_')
    }
  }
  handleClick():void{
    if(!this.load){
      this.onClick.emit()
    }
  }

  handleLinkClick(){
    this.router.navigate( [this.link], {fragment: this.fragment} )
  }


}
