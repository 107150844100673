export const appConstants = {

  TASK_STATUSES : {
    INACVTIVE: 'inactive',
    ACTIVE_PENDING: 'activePending',
    ACTIVE_DONE: 'activeDone',
    ACTIVE_TAKEN: 'activeTaken',
    EXPIRED: 'expired'
  },
  STATE_DATA_KEYS : {
    MAIN_EVENT:'main_event',
  },
  GuildChannelStatus: {
    ENABLE: 'enable',
    DISABLE: 'disable',
    INVALID: 'invalid',
    DELETED: 'deleted',
  },
  IMAGESIZES: [
    {
      id: 1,
      title: "1 X 1",
      width: "100",
      smWidth: "100",
      class: "size-1-1",
      cropperSettings: {
        resizeToWidth: 256,
        resizeToHeight: 256,
        ratio: 1 / 1,
        roundCropper: false
      }
    },
    {
      id: 2,
      title: "1 X 2",
      width: "80",
      smWidth: "40",
      class: "size-1-2",
      cropperSettings: {
        resizeToWidth: 500,
        resizeToHeight: 256,
        ratio: 1 / 2,
        roundCropper: false
      }
    },
    {
      id: 3,
      title: "3 X 4",
      width: "150",
      smWidth: "200",
      class: "size-3-4",
      cropperSettings: {
        resizeToWidth: 150,
        resizeToHeight: 200,
        ratio: 4 / 2,
        roundCropper: false
      }
    },
    {
      id: 4,
      title: "4 X 3",
      width: "200",
      smWidth: "150",
      class: "size-4-3",
      cropperSettings: {
        resizeToWidth: 1235,
        resizeToHeight: 338,
        ratio: 4 / 3,
        roundCropper: false
      }
    },

  ],
  PROVIDERS: {
    FACEBOOK: 'facebook',
    VK: 'vk',
    STEAM: 'steam',
    TWITCH: 'twitch',
    GOOGLE: 'google',
    DISCORD: 'discord'
  },

  ACTIONTYPES: {
    ADD: "ADD",
    UPDATE: "UPDATE",
    DELETE: "DELETE"
  },
  COMMENTFORTABLE: {
    EVENT: 'event',
    TOURNAMENT: 'tournament',
    PRODUCT: 'product',
    ARTICLE: 'article',
    CHALLENGE: 'challenge',
    SOCIAL_PAGE: 'socialPage',
    POST: 'post',
    BET_ON_ME: 'bet-on-me'
  },
  PLATFORMS: {
    PS4: "ps4",
    PS5: "ps5",
    XBOX: "xbox",
    XBOXXS: "xboxxs",
    NINTENDO: "nintendo",
    WINDOWS: "windows",
    ANDROID: "android",
    IOS: "ios",
    WEB: "web",
    OFFLINE: "offline",
    OTHER: "other",
  },
  POSTTABLE: {
    IMAGE: 'media',
    GAME: 'game',
    EVENT: 'event',
    BET_ON_ME: 'bet-on-me',
    CHALLENGE: 'challenge',
    TOURNAMENT: 'tournament'
  },

  EVENTTYPES: {
    USER: 'user',
    CHALLENGE: 'challenge',
    PARTICIPANT: 'participant',
    TASK_UPDATE: 'task_update',
    EVENT: 'event',
    TOURNAMENT: 'tournament',
    BETONME: 'betOnMe',
    COMMENT: 'comment',
    CHATROOM: 'chat',
    POST: 'post',
    LIKE: 'like',
    VOTE: 'vote',
    TEAM: 'team',
    PUBLISHER_PLAYERS_MANAGEMENT: 'publisher_players_management',
    PUBLISHER: 'publisher',
    CRAWLER_PROCESS: "crawler_process"
  },
  POSTTYPES: {
    MEDIA: 'media',
    TEXT: 'text',
    NEWS: 'news',
    REMOTE_TOURNAMENTS: 'remote',
    VIDEO: 'video',
    LIVE: 'live',
    BET_ON_ME: 'betOnMe',
    CHALLENGE: 'challenge',
    TOURNAMENT: 'tournament'
  },
  POSTSTATUS: {
    ACTIVE: 'active',
    PRIVATE: 'private',
    PUBLIC: 'public',
    DELETED: 'deleted',
    PENDING: 'PENDING',
    BLOCKED: 'blocked',
    FINISHED: 'finished'
  },
  UserStatus: {
    NEW: 'new',
    ACTIVE: 'active',
    FULLY_VERIFIED: 'fullyVerified',
    INACTIVE: 'inactive',
    DELETED: 'deleted',
    BLOCKED: 'blocked',
  },
  BETONMESTATUS: {
    PENDING: 'pending',
    ACTIVE: 'active',
    FINISHED: 'finished',
    INACTIVE: 'inactive',
    DELETED: 'deleted',
    PAUSED: 'paused'
  },
  MessageBodyType : {
    IMAGE: 'image',
    TEXT: 'text',
    VOTE: 'vote'
  },
  NOTIFICATIONTYPES: {
    ACTION_ONE_TIME: "actionOneTime",
    ACTION_UNTIL_ANSWER: "action",
    STANDARD: "standard",
    FRIEND_REQUEST: "friendRequest",
    ANSWER_FOR_DISPUTE: "answerForDispute",
    SHOW_WON_AMOUNT: 'showWonAmount',
    TOURNAMENT_LINK: "tournamentLink",
    TEMPORARY_TEAM_INVITATION: 'temporaryTeamInvitation',
    PUBLISHER_REQUEST: 'publisherRequest',
    QUESTION_FOR_DISPUTE: 'questionForDispute',
    QUESTION: 'question',
    ORDER_LINK: "orderLink",


  },
  TRANSACTIONSTYPES: {
    TOPUP: {display: "topup", value: "topup"},
    LEADERBOARD_REWARD: {display: "leaderboard_reward", value: "leaderboard_reward"},
    WITHDRAW: {display: "withdraw", value: "withdraw"},
    FROM_OUR_SIDE_ADD: {display: "from_our_side_add", value: "from_our_side_add"},
    FROM_OUR_SIDE_MINUS: {display: "from_our_side_minus", value: "from_our_side_minus"},
    FROM_OTHER_PLAYER_ADD: {display: "from_other_player_add", value: "from_other_player_add"},
    TO_OTHER_PLAYER_ADD: {display: "to_other_player_add", value: "to_other_player_add"},
    CANCELED: {display: "canceled", value: "canceled"},
    BID: {display: "bid", value: "bid"},
    TOPUP_FROM_BALANCE: {display: 'topup_from_balance', value: 'topup_from_balance'},
    WIN: {display: "win", value: "win"},
    TASK_BONUS: {display: "task_bonus", value: "task_bonus"},
    USE_SERVICE: {display: "use_service", value: "use_service"}

  },
  TransactionStatus: {
    PENDING: {display: "pending", value: "pending"},
    DONE: {display: "done", value: "done"},
    REFUNDED: {display: "refunded", value: "refunded"},
    CANCELED: {display: "canceled", value: "canceled"},
    HOLD: {display: "hold", value: "hold"},
  },

   Languages : {
     hy : "hy",
     en : "en",
     ru : "ru"
  },
   Currencies : {
    USD: 'USD',
    AMD: 'AMD',
    RUB: 'RUB'
  },
  Locations : {
    ARMENIA: 'Armenia',
    RUSSIA: 'Russia',
    USA: 'USA'
  },
  NotificationStatus: {
    SEEN: "seen",
    NOT_SEEN: "notSeen",
    DELETED: "deleted",
    ACCEPTED: "accepted",
    CANCELED: "canceled",
  },
  RoomType: {
    GROUP: 'group',
    INDIVIDUAL: 'individual',
    FOR_ADMIN: 'forAdmin',
    GENERATED: 'generated'

  },
  TeamStatus: {
    PENDING: 'pending',
    WIN: 'win',
    LOSS: 'loss',
    DRAW: 'draw',
    DELETED: 'deleted',
    COMPLETED: 'completed'
  },
  ParticipantsForTable:{
    CHALLENGE: 'challenge',
    TOURNAMENT: 'tournament',
    // EVENT: 'event',
    // BET_ON_ME: 'bet-on-me'
  },


  TaskType: {
    GUESS_WINNER: 'GUESS_WINNER',
    RICH_FRIEND: 'RICH_FRIEND',
    BECOME_SPONSOR: 'BECOME_SPONSOR',
    GIFT_PLAYER: 'GIFT_PLAYER',
    DISABLE_ADS: 'DISABLE_ADS',
    DAILY_PROFIT: 'DAILY_PROFIT',
    REWARDED_VIDEO_ADD: 'REWARDED_VIDEO_ADD',
    VIDEO_TASK: 'VIDEO_TASK',
    PICTURE_TASK: 'PICTURE_TASK',
    BECOME_WINNER: 'BECOME_WINNER',
    ACTIVATE_SUBSCRIPTION: 'ACTIVATE_SUBSCRIPTION',
    SUBSCRIBE_TO_PUBLISHER: 'SUBSCRIBE_TO_PUBLISHER',
    DOWNLOAD_APP: 'DOWNLOAD_APP',
    REVIEW_APP: 'REVIEW_APP',
    FILL_DATA: 'FILL_DATA',
    SHARE_LINK: 'SHARE_LINK',
    INVITE_BATTLE: 'INVITE_BATTLE',
    PARTICIPATE_TASK: 'PARTICIPATE_TASK',
    CONVERT_BALANCE: 'CONVERT_BALANCE',
    VISIT_BY_LINK: 'VISIT_BY_LINK',
    INVITE_NEW_GAME_PLAYER: 'INVITE_NEW_GAME_PLAYER',

    CONNECT_TWITCH: 'CONNECT_TWITCH',
    CONNECT_STEAM: 'CONNECT_STEAM',
    CONNECT_VK: 'CONNECT_VK',
    CONNECT_FB: 'CONNECT_FACEBOOK',
    CONNECT_GMAIL: 'CONNECT_GOOGLE',
    CONFIRM_EMAIL: 'CONFIRM_EMAIL',
    CONFIRM_PHONE: 'ADD_AND_VERIFY_PHONE_NUMBER',
    REFERRAL: 'REFERRAL'
  },

  AccountTaskStatus: {
    REJECTED: 'rejected',
    ACTIVE: 'active',
    EXPIRED: 'expired',
    IN_PROGRESS: 'inprogress',
    APPROVED: 'approved',
    DONE: 'done',
    TAKEN: 'taken'
  },

  PublisherRoleAccount: {
    ADMIN: 'admin',
    JUDGE: 'judge',
    OWNER: 'owner'
  },

  PublisherStatus: {
    ACTIVE: 'active',
    IN_REVIEW: 'in_review',
    NEED_CHANGES: 'need_changes',
    UNDER_CONSTRUCTION: 'under_construction',
    BLOCKED: 'blocked',
    INACTIVE: 'inactive',
    DELETED: 'deleted'
  },
   ChallengeStatus : {
    PENDING: 'pending',
    INREVIEW: 'in_review',
    CAN_PUBLISH: 'can_publish',
    ROUND_WAITING: 'round_waiting',
    ACTIVE: 'active',
    STARTED: 'started',
    DEFINE_RESULT: 'defineResult',
    FINISHED: 'finished',
    REJECTED: 'rejected',
    BLOCKED: 'blocked',
    DELETED: 'deleted'
  },
  PrizeMode : {
    DEFAULT: {value:'default', display: 'Default'},
    NEXT_ROUND: {value:'next_round', display: 'Next round'},
    PAY_FOR_SCORE: {value:'pay_for_score', display: 'Pay for score'},
    ONE_TEAM_WINNER: {value:'one_team_winner', display: 'One team winner'}
  },
  PrizeTypes : {
    MONEY: {value:'money', display: 'money'},
    TICKET: {value:'ticket', display: 'ticket'},
    PARTICIPATION_MAIN_CHALLENGE: {value:'participation_main_challenge', display: 'participation_main_challenge'},
    NEXT_ROUND: {value:'next_round', display: 'next_round_select_box'},
    NEXT_STAGE: {value:'next_stage', display: 'next_stage'},
    ITEM: {value:'item', display: 'item'},
  },

  StageTypes: {
    //LIGA: {value:'liga', display: 'liga'},
    PLAYOFF: {value:'playoff', display: 'playoff'},
  },
  StageStatus : {
    PENDING: 'pending',
    ACCEPTED: 'accepted',
    DECLINED: 'declined',
    ON_REVIEW: 'onReview',
    ON_COMPLETED_PROCESS: 'onCompletedProcess',
    STARTED: 'started',
    FINISHED: 'finished',
    REPORTED: 'reported',
    DELETED: 'deleted'
  },
  BannerStatus: {
    ACTIVE: {value:'active', display: 'Active'},
    DELETED: {value:'deleted', display: 'deleted'}
  },
  BannerType: {
    FULL_WIDTH: {value:'FULL_WIDTH', display: 'FULL_WIDTH'},
    VERTICAL: {value:'VERTICAL', display: 'VERTICAL'},
    FULL_WIDTH_WITH_BUTTON: {value:'FULL_WIDTH_WITH_BUTTON', display: 'FULL_WIDTH_WITH_BUTTON'},
    WITH_BUTTON: {value:'WITH_BUTTON', display: 'WITH_BUTTON'},
    RIGHT_MASK_BANNER: {value:'RIGHT_MASK_BANNER', display: 'RIGHT_MASK_BANNER'},
    LEFT_MASK_BANNER: {value:'LEFT_MASK_BANNER', display: 'LEFT_MASK_BANNER'},
  },
  ScoreLabels: {
    KILLS: {value:'kills', display: 'kills'},
    GOALS: {value:'goals', display: 'goals'},
    SCORE: {value:'score', display: 'score'},
    TIME: {value:'time', display: 'time'},
    CHIPS: {value:'chips', display: 'chips'},
    POINTS: {value:'points', display: 'points'},
    COINS: {value:'coins', display: 'coins'}
  },
  CalculateScoreType: {
    PRIMARY_THEN_IN_ORDER: {value: 'primaryThenInOrder', display: 'primaryThenInorder'},
    SUM_THEN_IN_ORDER: {value: 'sumThenInOrder', display: 'sumThenInOrder'}
  },
   ProductCategories : {
     INFLUENCER: {value:'influencer', display: 'influencer'},
     COACH: {value:'coach', display: 'coach'},
     STREAM: {value:'stream', display: 'stream'},
     JUDGE: {value:'judge', display: 'judging'},
     DESIGN: {value:'design', display: 'design'},
     DIGITAL_PRODUCTS: {value:'digital_products', display: 'digital_products'},
     PROMOTIONS: {value:'promotions', display: 'promotions'},
     OTHER: {value:'other', display: 'other'},

  },
   OrderStatus: {
    DONE: 'done',
    PENDING: 'pending',  //spasman pul mijev vajaroxy chynduni
    IN_PROGRESS: 'inProgress',// patvery ynduneluc heto
    DELIVERED: 'delivered', // befor succes
    REJECTED: 'rejected', // if celler rejected
    REFUSED: 'refused', // if celler rejected after accept
    CANCELED: 'canceled', //if buyer canseled
    DELETED: 'deleted'
  },

  TaskForTable: {
    TOURNAMENT: 'tournament',
    CHALLENGE: 'challenge',
    GLOBAL: null
  },
  TaskTypesForParticipate: {
    ENTER_REQUIRED_FIELDS: 'enterRequiredFields',
    WAITING_CONFIRMATION_FROM_ADMIN: 'waitingConfirmationFromAdmin',
    CONDITIONS_FOR_PARTICIPATE: 'conditionsForParticipate'
  },
  TASK_TYPES: {
    // CONNECT_DISCORD_BOT: {value:'CONNECT_DISCORD_BOT', display: 'CONNECT_DISCORD_BOT'},
    CONNECT_TELEGRAM_BOT: {value:'CONNECT_TELEGRAM_BOT', display: 'CONNECT_TELEGRAM_BOT'},
    // CONNECT_YOUTUBE_ACCOUNT: {value:'CONNECT_YOUTUBE_ACCOUNT', display: 'CONNECT_YOUTUBE_ACCOUNT'},
    //
    JOIN_TELEGRAM_CHANNEL: {value:'JOIN_TELEGRAM_CHANNEL', display: 'JOIN_TELEGRAM_CHANNEL'},
    // JOIN_DISCORD_CHANNEL: {value:'JOIN_DISCORD_CHANNEL', display: 'JOIN_DISCORD_CHANNEL'},
    SUBSCRIBE_TO_ME: {value:'SUBSCRIBE_TO_ME', display: 'SUBSCRIBE_TO_ME'},
    // SUBSCRIBE_TO_YOUTUBE_CHANNEL: {value:'SUBSCRIBE_TO_YOUTUBE_CHANNEL', display: 'SUBSCRIBE_TO_YOUTUBE_CHANNEL'},
    //
    // LIKE_YOUTUBE_VIDEO: {value:'LIKE_YOUTUBE_VIDEO', display: 'LIKE_YOUTUBE_VIDEO'},
    // WRITE_YOUTUBE_COMMENT: {value:'WRITE_YOUTUBE_COMMENT', display: 'WRITE_YOUTUBE_COMMENT'},
    //
    SHARE_POST: {value:'SHARE_POST', display: 'SHARE_POST'},
    BUY_PRODUCT: {value:'BUY_PRODUCT', display: 'BUY_PRODUCT'},
    VERIFY_PHONE_NUMBER: {value:'VERIFY_PHONE_NUMBER', display: 'VERIFY_PHONE_NUMBER'},
    VERIFY_IDENTITY: {value:'VERIFY_IDENTITY', display: 'VERIFY_IDENTITY'},
    UPLOAD_AVATAR: {value:'UPLOAD_AVATAR', display: 'UPLOAD_AVATAR'}
  },
  TASK_TYPE_INPUTS: {
    CONNECT_DISCORD_BOT: [],
    CONNECT_TELEGRAM_BOT: [],
    CONNECT_YOUTUBE_ACCOUNT: [],
    JOIN_TELEGRAM_CHANNEL: [
      {type: 'text', label: 'enter_telegram_group_url', value: ''},
    ],
    JOIN_DISCORD_CHANNEL: [
      {type: 'text', label: 'enter_discord_invite_group_url_without_expire', value: ''}
    ],
    SUBSCRIBE_TO_ME: [],
    SUBSCRIBE_TO_YOUTUBE_CHANNEL: [
      {type:'text', label: 'VIDEO_URL', value: ''}
    ],

    LIKE_YOUTUBE_VIDEO: [
      {type:'text', label: 'VIDEO_URL', value: ''}
    ],
    WRITE_YOUTUBE_COMMENT: [
      {type:'text', label: 'VIDEO_URL', value: ''}
    ],

    SHARE_POST: [
      {type:'text', label: 'CONTENT_TEXT_URL', value: ''},
      {type:'text', label: 'CONTENT_TEXT', value: ''}
    ],
    BUY_PRODUCT: [
      {type:'text', label: 'PUT_PRODUCT_LINK_IN_FROM_SHOP', value: ''}
    ],
    VERIFY_PHONE_NUMBER: [],
    VERIFY_IDENTITY: [],
    UPLOAD_AVATAR: [],
    LOCATION: []
  }

}
