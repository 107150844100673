<div class='pub_load_cont' *ngIf='loading'>
  <div class='switching-info-container'>
    <div class='profile-loader-wrapper'>
      <app-image-wrapper [avatar]='imgUrl' [class]="'loader-img'"></app-image-wrapper>
      <svg width='134' height='134' viewBox='0 0 135 135' fill='none' xmlns='http://www.w3.org/2000/svg'
          >
        <path
          d='M 119.0842 110.9382 C 106.7576 125.4804 88.3567 134.7108 67.8 134.7108 C 30.6865 134.7108 0.6 104.624 0.6 67.5106 C 0.6 58.43 2.4011 49.7701 5.6654 41.8686 C 6.067 40.897 7.2342 40.5205 8.1446 41.0462 C 8.929 41.499 9.2436 42.4687 8.8991 43.3062 C 5.8296 50.768 4.1368 58.9414 4.1368 67.5102 C 4.1368 102.6703 32.6396 131.1732 67.7999 131.1732 C 88.3117 131.1732 106.5578 121.4724 118.201 106.409 C 118.4627 106.0704 118.8236 105.8446 119.2142 105.7487 C 119.4774 105.1025 120.1116 104.647 120.8532 104.647 C 121.8288 104.647 122.6208 105.4387 122.6208 106.4154 V 120.5628 C 122.6208 121.5396 121.8288 122.3316 120.8532 122.3316 C 119.8759 122.3316 119.0842 121.5396 119.0842 120.5628 V 110.9382 Z M 129.4728 94.2425 C 129.0588 95.1954 127.9056 95.5564 127.0056 95.037 C 126.2112 94.5782 125.9004 93.59 126.2652 92.7478 C 129.6096 85.0097 131.4636 76.4764 131.4636 67.5102 C 131.4636 32.35 102.9601 3.847 67.7999 3.847 C 46.885 3.847 28.3255 13.9326 16.72 29.5057 C 16.4028 29.9312 15.9305 30.1855 15.4349 30.235 C 15.2234 30.3242 14.9911 30.3736 14.7473 30.3736 C 13.7706 30.3736 12.9788 29.5818 12.9788 28.6051 V 14.4578 C 12.9788 13.4812 13.7706 12.6894 14.7473 12.6894 C 15.724 12.6894 16.5156 13.4812 16.5156 14.4578 V 24.083 C 28.8422 9.5408 47.2432 0.3106 67.8 0.3106 C 104.9135 0.3106 135 30.397 135 67.5106 C 135 77.0105 133.0284 86.0501 129.4728 94.2425 Z'
          style='fill:white' transform='scale(1, -1) translate(0, -135)'></path>
      </svg>
    </div>
    <div class='switch-tip-container'>
      <span>Switching to {{nickname}}...</span>
    </div>
  </div>
</div>
