import { Injectable , EventEmitter} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpService} from "./http.service";
import {Observable} from "rxjs";

@Injectable()
export class OpenaiService {
  private addUrl = '/ai-routes/new-question'
  private generateAvatarUrl = '/ai-routes/generate-avatar'

  constructor(private networkHelper: HttpService) { }

  newQuestion(data: any): Observable<any> {
    return this.networkHelper.post(environment.apiURL + this.addUrl, data);
  }

  newAvatarImage(data: any): Observable<any> {
    return this.networkHelper.post(environment.apiURL + this.generateAvatarUrl, data);
  }


}
