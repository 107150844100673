import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpService } from './http.service';

@Injectable()
export class BlockService {

  private blockListUrl = '/blocking/list';
  private blockUrl = '/blocking/block';
  private unblockUrl = '/blocking/unblock/:id';


  constructor(private networkHelper: HttpService) {
  }

  blockList(pagination: any, filter: any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.blockListUrl, { ...pagination, ...filter });
  }
  block(data:any): Observable<any> {
    return this.networkHelper.post(environment.apiURL +
      this.blockUrl, data);
  }
  unblock(id: any): Observable<any> {
    return this.networkHelper.put(environment.apiURL +
      this.unblockUrl.replace(':id', id), {});
  }

}
