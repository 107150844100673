import { Component, OnInit } from '@angular/core';
import {StorageService} from "../../services/storage.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {PopupHelperService} from "../../services/popup-helper.service";
import {MeService} from "../../services/me.service";
import {Router} from "@angular/router";
import {EventManagerService} from "../../services/event-manager.service";

@Component({
  selector: 'app-add-nickname',
  templateUrl: './add-nickname.component.html',
  styleUrls: ['./add-nickname.component.scss']
})
export class AddNicknameComponent implements OnInit {

  constructor(private popupHelperService: PopupHelperService,
              private formBuilder: FormBuilder,
              private meService: MeService,
              private router: Router,
              private eventManagerService: EventManagerService) { }
  addNicknameForm: FormGroup;
  errorMessage: String;


  ngOnInit(): void {
    this.addNicknameForm = this.formBuilder.group({
        nickname: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(12)]),
    });
  }

  onSubmit(){
    const formData = this.addNicknameForm.value;

    if (this.addNicknameForm.invalid){
      for (const key in this.addNicknameForm.controls) {
        if (this.addNicknameForm.controls[key].untouched && !this.addNicknameForm.controls[key].value) {
          this.addNicknameForm.controls[key].markAsTouched();
          this.addNicknameForm.controls[key].setValue(1);
          this.addNicknameForm.controls[key].setValue('');
        }
      }
      return false;
    }else{
      this.meService.addNickname(formData).subscribe( (data:any) => {
        if (!data.hasError) {
          let currentUser = StorageService.getJsonItem(StorageService.statics.currentUser);
          currentUser.nickname =  formData.nickname;
          currentUser.status =  'active';
          StorageService.setJsonItem(StorageService.statics.currentUser, currentUser);
          this.router.navigate(['/']);
        } else {
          this.errorMessage = data.errorMessages[0].msg;
          this.popupHelperService.printError({text:this.errorMessage});
        }
      });
    }
  }
}
