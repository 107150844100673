
  <button *ngIf="!link && !url" [id]="id"  (click)="handleClick()" class="primary-btn  {{disabled?'disabled':''}} {{className}} {{load?'activeLoading':''}}" [style]="style" [disabled]='disabled'>
    <i  *ngIf="icon" class="fas {{icon}}"></i>
    {{text}} <span class="load loading"></span>
  </button>

  <a *ngIf="link && !url" [id]="id"  (click)='handleLinkClick()' class="primary-btn {{className}} {{load?'activeLoading':''}}">
    <i *ngIf="icon"  class="fas {{icon}}"></i>
    {{text}}
  </a>

  <a *ngIf="!link && url" [id]="id"  [href]="[url]" target="_blank" class="primary-btn {{className}} {{load?'activeLoading':''}}">
    <i *ngIf="icon" class="fas {{icon}}"></i>
    {{text}}
  </a>
