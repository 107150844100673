import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HttpService} from "./http.service";
import {environment} from "../../environments/environment";

@Injectable()
export class ChallengeService {

  private getSettingInfoUrl = '/challenge/:id';
  private getChildChallengesUrl = '/challenge/:id/child-challenges';
  private sendToDiscordUrl = '/challenge/:id/send-to-discord';
  private getListUrl = '/challenge/list';
  private getPopupInfoByIdUrl = '/challenge/info/:id';
  private getGroupUrl = '/challenge/group/:id';

  ////

  constructor(private networkHelper: HttpService) {
  }



  getItems(pagination:any, filter:any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.getListUrl,{...pagination, ...filter});
  }


  getById(id: any, makeSeen = false): Observable<any> {
    return this.networkHelper.get(environment.apiURL +
      this.getSettingInfoUrl.replace(':id', id)+'?seen='+makeSeen);
  }

  sendToDiscord(id: any, publisherId:any): Observable<any> {
    return this.networkHelper.post(environment.apiURL +
      this.sendToDiscordUrl.replace(':id', id),{publisherId});
  }

  getPopupInfoById(id: any): Observable<any> {
    return this.networkHelper.get(environment.apiURL +
      this.getPopupInfoByIdUrl.replace(':id', id));
  }

  getByGroupId(id: any): Observable<any> {
    return this.networkHelper.get(environment.apiURL +
      this.getGroupUrl.replace(':id', id));
  }

  update(id: any, data: any): Observable<any> {
    return this.networkHelper.put(environment.apiURL +
      this.getSettingInfoUrl.replace(':id', id), data);
  }

  delete(id: any): Observable<any> {
    return this.networkHelper.del(environment.apiURL +
      this.getSettingInfoUrl.replace(':id', id));
  }
  getChildChallenges(id: any): Observable<any> {
    return this.networkHelper.get(environment.apiURL +
      this.getChildChallengesUrl.replace(':id', id));
  }
}
