import {Pipe, PipeTransform} from '@angular/core';
import {Observable, of} from 'rxjs';
import {customSetInterval} from "../../../helpers/overrided-timers";

@Pipe({
  name: 'timer'
})
export class TimerPipe implements PipeTransform {
  eventId: any;
  expired = false;
  expiredText = '';

  constructor(){}

  /**
   * @param futureDate    should be in a valid Date Time format
   *                      e.g. YYYY-MM-DDTHH:mm:ss.msz
   *                      e.g. 2021-10-06T17:27:10.740z
   */
  public transform(futureDateUtc: string, text: string = 'Expired'): Observable<string> {
    /**
     * Initial check to see if time remaining is in the future
     * If not, don't bother creating an observable
     */
    if(typeof futureDateUtc === 'number'){
      let time = new Date(futureDateUtc)
      time.toLocaleString('en-US', { timeZone: 'Etc/GMT' })
      futureDateUtc = time.toISOString()
    }
    this.expiredText = text;
    if (!futureDateUtc || this.getMsDiff(futureDateUtc) < 0) {
      return of(text);
    }
    let runningTimeObservable:any = this.msToTime(this.getMsDiff(futureDateUtc))
    runningTimeObservable = new Observable<any>(observer => {
      customSetInterval(() => {
        observer.next(this.msToTime(this.getMsDiff(futureDateUtc)))
        return
      }, 1000);
    });
    return runningTimeObservable
  }

  /**
   * Gets the millisecond difference between a future date and now
   * @private
   * @param   futureDateUtc: string
   * @returns number  milliseconds remaining
   */
    // Z converts to local time
  private getMsDiff = (futureDate: string): number => (+(new Date(futureDate).getTime()) - Date.now());


  /**
   * Converts milliseconds to the
   *
   * @private
   * @param msRemaining
   * @returns null    when no time is remaining
   *          string  in the format `HH:mm:ss`
   */
  private msToTime(msRemaining: number): string {
    if (msRemaining < 0) {
      this.expired = true;
      return this.expiredText;
    }

    let seconds: string | number = Math.floor((msRemaining / 1000) % 60),
      minutes: string | number = Math.floor((msRemaining / (1000 * 60)) % 60),
      hours: string | number = Math.floor((msRemaining / (1000 * 60 * 60)) % 24),
      days: string | number = Math.floor((msRemaining / (1000 * 60 * 60)) / 24);
    /**
     * Add the relevant `0` prefix if any of the numbers are less than 10
     * i.e. 5 -> 05
     */
    let timer = ''
    if(days !== 0){
      days = (days < 10) ? '0' + days : days;
      timer = timer + `${days}:`;
    }
    if(hours !== 0){
      hours = (hours < 10) ? '0' + hours : hours;
      timer = timer + `${hours}:`;
    }
    if(minutes !== 0){
      minutes = (minutes < 10) ? '0' + minutes : minutes;
      timer = timer + `${minutes}:`;
    }
    seconds = (seconds < 10) ? '0' + seconds : seconds;
    return `${timer}${seconds}`;
  }

}
