import {Pipe, PipeTransform} from '@angular/core';
import {StorageService} from "../../../services/storage.service";

@Pipe({
  name: 'backTranslate'
})
export class BackTranslatePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    let lang = StorageService.getItem(StorageService.statics.lang);
    if(!lang){
      lang = 'en'
    }
    let content = value?.find((data:any) => data.lang === lang)
    if(!content  && value?.length > 0){
      content = value[0];
    }else if( !content ) {
      content = {content: 'not_translated'}
    }
    return content.content;
  }
}
