import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { EventManagerService } from '../../../../services/event-manager.service';
import {TranslateService} from "@ngx-translate/core";
import { Router } from '@angular/router';
import {WaitingToastyComopnent} from "./waiting-toasty.comopnent";

@Component({
  selector: 'app-toasty',
  template: '',
  encapsulation: ViewEncapsulation.None
})
export class ToastyComponent implements OnInit {
  allToasts: any = []
  constructor(private toastyService:ToastrService,
              private eventManagerService: EventManagerService,
              private translateService: TranslateService,
              private router: Router) {
    // Assign the selected theme name to the `theme` property of the instance of ToastyConfig.
    // Possible values: default, bootstrap, material
  }
  ngOnInit() {
    this.eventManagerService.showToastyEmitter.subscribe((options: any) => {
      console.log('sassss')
      this.addToast(options)
    })

    this.eventManagerService.changeToastyProgressEmitter.subscribe((data: any) => {
      let index = this.allToasts.findIndex((x: any) => x.options.forId === data.forId)
      if(index >= 0){
        if(data.title){
          this.allToasts[index].toast.title = data.titleHref?`<a routerLink='${data.titleHref}' >${ this.translateService.instant(data?.title.toString())}</a>`: this.translateService.instant(data?.title.toString())
        }
        if(this.allToasts[index].options.showProgress){
          this.allToasts[index].toast.toastRef.componentInstance.options.progressBar = this.generateProgressBar(data.currentValue, data.maxValue)
          //this.allToasts[index].toast.toastRef.componentInstance.options.messageClasss = this.generateProgressBar(data.currentValue, data.maxValue).toString()
        }
      }
    })

    this.eventManagerService.hideToastyEmitter.subscribe((data: any) => {
      let index = this.allToasts.findIndex((x: any) => x.options.forId === data.forId)
      if(index >= 0){
        this.removeToasty(this.allToasts[index].toast.id)
      }

    })

  }


  addToast(options: any) {
    console.log('sas')
    let title = (options.titleHref?`<a routerLink='${options.titleHref}' >${this.translateService.instant(options?.title.toString())}</a>`: this.translateService.instant(options?.title.toString()))
    let message =  options?.msg;
    let progress = this.generateProgressBar(options.currentValue, options.maxValue)
    let type = options.type?options.type: 'success'
    options.enableHtml = true;
    options.disableTimeOut = true;
    if(type==='waiting'){
      options.toastComponent = WaitingToastyComopnent;
    }
    switch (type) {
      case 'default': this.allToasts.push({toast: this.toastyService.show(message, title, options), options}); break;
      case 'info': this.allToasts.push({toast: this.toastyService.info(message, title, options), options}); break;
      case 'success': this.allToasts.push({toast: this.toastyService.success(message, title, options), options}); break;
      case 'error': this.allToasts.push({toast: this.toastyService.error(message, title, options), options}); break;
      case 'warning': this.allToasts.push({toast: this.toastyService.warning(message, title, options), options}); break;
      default: this.allToasts.push({toast: this.toastyService.show(message, title, options), options}); break;
    }
  }

  removeAllToasty() {
    this.toastyService.clear();
  }

  removeToasty(toastId: any) {
    let index = this.allToasts.findIndex((x: any) => x.toast.id === toastId)
    if(index >= 0){
      this.allToasts.splice(index, 1);
    }
    this.toastyService.clear(toastId);
  }

  generateProgressBar(currentValue: any, maxValue: any){
    let procent: any = (currentValue/maxValue) * 100
    return parseInt(procent)
  }

}
