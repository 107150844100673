import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HttpService} from "./http.service";
import {environment} from "../../environments/environment";

@Injectable()
export class StageService {

  private getInfoUrl = '/stage/:id';
  private getPrizesUrl = '/stage/:id/prizes';


  constructor(private networkHelper: HttpService) {
  }



  getById(id: any): Observable<any> {
    return this.networkHelper.get(environment.apiURL +
      this.getInfoUrl.replace(':id', id));
  }
  getPrizesById(id: any): Observable<any> {
    return this.networkHelper.get(environment.apiURL +
      this.getPrizesUrl.replace(':id', id));
  }


}
