import { Injectable } from '@angular/core';
import {HttpService} from './http.service';
import {Observable} from 'rxjs';
import {environment} from "../../environments/environment";

@Injectable()
export class LocalizationService {

    private getTicketPriceToCurrencyUrl = '/localization/ticketPriceForUser';

    constructor(private networkHelper: HttpService) { }

    getTicketPriceToCurrency(query: any = {}): Observable<any> {
        return this.networkHelper.get(environment.apiURL +
            this.getTicketPriceToCurrencyUrl, query);
    }

}
