import { Injectable } from '@angular/core';
import {HttpService} from './http.service';
import {environment} from '../../environments/environment';
import {Observable} from "rxjs";

@Injectable()
export class MeService {

  constructor(private networkHelper: HttpService) { }


  private getProfileUrl = '/me/get-profile';
  private editProfileUrl = '/me/edit-profile';
  private editCurrencyUrl = '/me/edit-currency';
  private getNotificationsListUrl = '/me/notification-list';
  private editPaswordUrl = '/me/reset-password';
  private getTransactionUrl = '/me/transaction/list';
  private getWithdrawUrl = '/balance/withdraw';
  private getInitialInfoUrl = '/me/initial-info';
  private notificationSeenUrl = '/me/notification/seen/:id';
  private getNotificationUrl = '/me/notification/:id';
  private notificationSeenAllUrl = '/me/notification/all-seen';
  private getLinkedAccountsUrl = '/me/get-linked-accounts';
  private addNicknameUrl = '/me/add-nickname';
  private closeNotificationModalUrl = '/me/close-notification-modal/:id';

  private getTaskListUrl = '/me/task-list';
  private getTaskTemplateRewardUrl = '/task-management/take-reward/:id';
  private getTaskRewardUrl = '/me/take-task/:id';

  private getTaskWriteContentUrl = '/me/take-task/content/:id';
  private notifyByTelegramUrl = '/me/notify-by-telegram';
  private unsubscribeByTelegramUrl = '/me/unsubscribe-by-telegram';
  private deleteAccountUrl = '/me/delete-account';
  private getPublisherInfo = '/me/get-publisher-info';
  private getUserExperienceUrl = '/me/user-experience';
  private getMyCurrentLeaderboardScoreUrl = '/me/leaderboard-score/:id';



  getProfile(): any {
    return this.networkHelper.get<any>(environment.apiURL + this.getProfileUrl);
  }
  getNotificationsList(query:any){
    return this.networkHelper.get<any>(environment.apiURL + this.getNotificationsListUrl, query);
  }
  notificationSeen(notificationId: string){
    return this.networkHelper.put(environment.apiURL + this.notificationSeenUrl.replace(':id', notificationId), {});
  }

  notificationSeenAll(){
    return this.networkHelper.put(environment.apiURL + this.notificationSeenAllUrl, {});
  }

  notificationDelete(notificationId: string){
    return this.networkHelper.del(environment.apiURL + this.getNotificationUrl.replace(':id', notificationId));
  }

  updateProfile(data:any): any {
    return this.networkHelper.put<any>(environment.apiURL + this.editProfileUrl,data);
  }
  updateCurrency(data:any): any {
    return this.networkHelper.put<any>(environment.apiURL + this.editCurrencyUrl,data);
  }

  updatePassword(data:any): any {
    return this.networkHelper.put<any>(environment.apiURL + this.editPaswordUrl, data);
  }

  updateSettings(data: any): any {
    return this.networkHelper.get<any>(environment.apiURL + this.getProfileUrl, data);
  }

  getDashboardInfo(): any {
    return this.networkHelper.get<any>(environment.apiURL + this.getPublisherInfo);
  }

  getTransactionList(pagination: {} | undefined, filter: {} | null | undefined): Observable<any> {
    return this.networkHelper.get<any>(environment.apiURL + this.getTransactionUrl,{...pagination, ...filter});
  }

  getMyTournaments(): any {

  }

  getSettings():any{

  }

  withdraw(data:any):any{
    return this.networkHelper.post<any>(environment.apiURL + this.getWithdrawUrl,data);
  }

  topup():any{

  }

  cancelWithdraw(){

  }
  getInitialInfo(){
    return this.networkHelper.get<any>(environment.apiURL + this.getInitialInfoUrl);
  }

  getPublisherInitialInfo(){
    return this.networkHelper.get<any>(environment.apiURL + this.getInitialInfoUrl);
  }

  getLinkedAccounts(){
    return this.networkHelper.get<any>(environment.apiURL + this.getLinkedAccountsUrl);
  }
  addNickname(data: any){
    return this.networkHelper.put<any>(environment.apiURL + this.addNicknameUrl, data);
  }
  closeNotificationModal(id: string){
    return this.networkHelper.put<any>(environment.apiURL + this.closeNotificationModalUrl.replace(':id', id), {});
  }


  getReward(id: string,data: object){
    return this.networkHelper.put<any>(environment.apiURL + this.getTaskRewardUrl.replace(':id', id), data);
  }
  getTemplateReward(id: string,data: object){
    return this.networkHelper.put<any>(environment.apiURL + this.getTaskTemplateRewardUrl.replace(':id', id), data);
  }

  writeContent(id: string, content: string){
    return this.networkHelper.put<any>(environment.apiURL + this.getTaskWriteContentUrl.replace(':id', id), {content});
  }

  getTaskList():any{
    return this.networkHelper.get<any>(environment.apiURL + this.getTaskListUrl);
  }
  notifyByTelegram(data: any):any{
    return this.networkHelper.post<any>(environment.apiURL + this.notifyByTelegramUrl, data);
  }
  unsubscribeByTelegram(data: any):any{
    return this.networkHelper.post<any>(environment.apiURL + this.unsubscribeByTelegramUrl, data);
  }
  deleteAccount(passData: any): any{
    return this.networkHelper.post<any>(environment.apiURL + this.deleteAccountUrl, passData);
  }

  checkUserExperience(data:any): any {
    return this.networkHelper.put<any>(environment.apiURL + this.getUserExperienceUrl, data);
  }

  getMyCurrentLeaderboardScore(id: any): any{
    return this.networkHelper.get<any>(environment.apiURL + this.getMyCurrentLeaderboardScoreUrl.replace(':id', id));
  }
}
