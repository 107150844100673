import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {StorageService} from "../../../services/storage.service";
import {AuthenticationService} from "../../../services/authentication.service";
import {PopupHelperService} from "../../../services/popup-helper.service";
import {isPlatformBrowser} from "@angular/common";
import {WindowRef} from "../../../helpers/windowRef.service";

@Component({
  selector: 'app-auth-activate-user-token',
  templateUrl: './auth-activate-user-token.component.html',
  styleUrls: ['./auth-activate-user-token.component.scss']
})
export class AuthActivateUserTokenComponent implements OnInit {

  constructor(private router: Router,
              private route: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private popupHelperService: PopupHelperService,
              @Inject(PLATFORM_ID) private platformId: any,
              private windowRef: WindowRef) {

  }
  public load = false
  ngOnInit(): void {
    let token = this.route.snapshot.paramMap.get('token');
    if (token) {
      this.load = true
      this.authenticationService.activateUser(token).subscribe((data:any)=>{
        if (!data.hasError) {
          StorageService.setJsonItem(StorageService.statics.currentUser, data.data.loginData);
          StorageService.setItem(StorageService.statics.token, data.data.loginData.token);
          let currentLang = StorageService.getItem(StorageService.statics.lang);
          if(currentLang !== data.data.loginData.language){
            StorageService.setItem(StorageService.statics.lang, data.data.loginData.language);
            this.router.navigate(['/'])
              .then(() => {
                if(isPlatformBrowser(this.platformId)) {
                  this.windowRef.nativeWindow.location.reload();
                }
              });
          }else {
            this.router.navigate(['/']);
          }
          this.load = false
        } else {
          this.load = false
          this.popupHelperService.printError({text: data.errorMessages[0].msg});
        }
      })
    }
  }
}
