import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import { WindowReferenceService } from './windowReferenceService';
import {WindowRef} from "./windowRef.service";
import {isPlatformBrowser} from "@angular/common";


@Injectable({
  providedIn: 'root'
})
export class DataLayerService {

  constructor (@Inject(PLATFORM_ID) private platformId: any,
    private windowRef: WindowRef)
  {

  }

  private pingHome(obj: any)
  {
    if(isPlatformBrowser(this.platformId)) {
      if(obj)  this.windowRef.nativeWindow.dataLayer.push(obj);
    }
  }


  //list of all our dataLayer methods

  logPageView(url: any)
  {
    const hit = {
      event: 'content-view',
      pageName: url
    };
    this.pingHome(hit);
  }

  logEvent(event: any,category: any,action: any,label: any)
  {
    const hit = {
      event:event,
      category:category,
      action:action,
      label: label
    }
    this.pingHome(hit);
  }

  logCustomDimensionTest(value: any)
  {
    const hit = {
      event:'custom-dimension',
      value:value
    }
    this.pingHome(hit);
  }

  // .. add more custom methods as needed by your app.
}
