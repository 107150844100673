import { Injectable , EventEmitter} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpService} from "./http.service";
import {Observable} from "rxjs";

@Injectable()
export class NotificationService {
  private answerUrl = '/notification/answer/{{id}}'


  constructor(private networkHelper: HttpService) { }

  answer(id: string, data: any): Observable<any> {
    return this.networkHelper.post(environment.apiURL + this.answerUrl.replace('{{id}}', id), data);
  }

}
