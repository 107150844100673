import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-validation-error',
  templateUrl: './validation-error.component.html',
  styleUrls: ['./validation-error.component.scss']
})
export class ValidationErrorComponent implements OnInit,OnChanges {

  @Input() control: FormControl;
  @Input() customMessage: string;
  @Input() concatError: string;
  @Input() rerender: any;
  public errors = [];

  constructor() { }

  ngOnInit(): void {
    this.geterr()
  }
  geterr(){
    const selfObject: any = this;
    // selfObject.errors = [];
    if(this.control != undefined){
      this.control.valueChanges.subscribe((e) => {
        let errors = []
        for (const key in selfObject.control.errors) {
          if (selfObject.control.errors[key]) {
            errors[errors.length] = key;
          }
        }
        selfObject.errors = errors;
      });
    }
  }
  ngOnChanges() {
    this.errors = []
    this.geterr()
  }
}
