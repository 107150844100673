import { Injectable } from '@angular/core';
import {HttpService} from './http.service';
import {StorageService} from './storage.service';
import {environment} from '../../environments/environment';

@Injectable()
export class AuthenticationService {

  constructor(private networkHelper: HttpService) { }


  private registerUrl = '/auth/registration';
  private authUrl = '/auth/login';
  private activateUrl = '/auth/activate';
  private activateUserUrl = '/auth/activateUser';
  private logoutUrl = '/auth/logout';
  private forgotPasswordStep1 = '/auth/forgot-step-1';
  private forgotPasswordStep2 = '/auth/forgot-step-2';
  private checkEmailUrl = '/auth/checkEmail/{{email}}';
  private checkNicknameUrl = '/auth/checkNickName/{{nickname}}';
  private resendActivationEmailUrl = '/auth/resendActivation/{{email}}';
  private checkEmailConfirmUrl = '/auth/checkIsConfirmed/{{email}}';
  private loginPartnersUrl = '/auth/partnerLogin';
  private callbackFbUrl = '/auth/facebook/callback';
  private callbackVkUrl = '/auth/vk/callback';
  private callbackStemUrl = '/auth/steam/callback';
  private callbackTwitchUrl = '/auth/twitch/callback';
  private callbackDiscordUrl = '/auth/discord/callback';
  private callbackDiscordClanUrl = '/auth/discord-clan/callback';

  private callbackGoogleUrl = '/auth/google/callback';
  private resendEmailUrl = '/auth/send-activation-token';
  private disconnectLinkedAccountsUrl = '/auth/:providerId';

  registration(userEntity: any): any {
    return this.networkHelper.post<any>(environment.apiURL + this.registerUrl, userEntity);
  }

  login(userEntity: any): any {
    return this.networkHelper.post<any>(environment.apiURL + this.authUrl, userEntity);
  }


  logout() {
    // remove user from local storage to log user out
    return this.networkHelper.get<any>(environment.apiURL + this.logoutUrl);
  }
  activate( activationToke: string): any {
    return this.networkHelper.get<any>(environment.apiURL + this.activateUrl + '/' + activationToke);
  }
  activateUser( activationToke: string): any {
    return this.networkHelper.get<any>(environment.apiURL + this.activateUserUrl + '/' + activationToke);
  }

  //
  // checkEmail(email ): any {
  //   return this.networkHelper.getWithoutLoading<any>(environment.apiURL + this.checkEmailUrl.replace('{{email}}', email));
  // }
  // checkNickname(nickname ): any {
  //   return this.networkHelper.getWithoutLoading<any>(environment.apiURL + this.checkNicknameUrl.replace('{{nickname}}', nickname));
  // }
  // resendActivationEmail(email): any {
  //   return this.networkHelper.get<any>(environment.apiURL + this.resendActivationEmailUrl.replace('{{email}}', email));
  // }
  // checkEmailConfirm(email): any {
  //   return this.networkHelper.getWithoutLoading<any>(environment.apiURL + this.checkEmailConfirmUrl.replace('{{email}}', email));
  // }
  //
  //
  //
  // logout(): any {
  //   return this.networkHelper.get<any>(environment.apiURL + this.logoutUrl);
  // }
  //
  forgotPassword(forgotPasswordData:any): any {
    // remove user from local storage to log user out
    if (forgotPasswordData.token) {
      return this.networkHelper.post<any>(environment.apiURL + this.forgotPasswordStep2, forgotPasswordData);
    } else {
      return this.networkHelper.post<any>(environment.apiURL + this.forgotPasswordStep1, forgotPasswordData);
    }
  }
  //
  // loginThirdParty(redirectUrl:string, clientId:string) {
  //   return this.networkHelper.post<any>(environment.apiURL + this.loginPartnersUrl, {redirectUrl,clientId});
  //
  // }

  callbackFb(params: string, body: any){
    return this.networkHelper.post<any>(environment.apiURL + this.callbackFbUrl + "?" + params, body);
  }
  callbackVk(params: string, body: any){
    return this.networkHelper.post<any>(environment.apiURL + this.callbackVkUrl + "?" + params, body);
  }
  callbackSteam(params: string, body: any){
    return this.networkHelper.post<any>(environment.apiURL + this.callbackStemUrl + "?" + params, body);
  }
  callbackTwitch(params: string, body: any){
    return this.networkHelper.post<any>(environment.apiURL + this.callbackTwitchUrl + "?" + params, body);
  }
  callbackGoogle(params: string, body: any){
    return this.networkHelper.post<any>(environment.apiURL + this.callbackGoogleUrl + "?" + params, body);
  }
  callbackDiscord(params: string, body: any){
    return this.networkHelper.post<any>(environment.apiURL + this.callbackDiscordUrl + "?" + params, body);
  }

  callbackDiscordClan(params: string, body: any){
    return this.networkHelper.post<any>(environment.apiURL + this.callbackDiscordClanUrl + "?" + params, body);
  }
  disconnectLinkedAccounts(providerId: string){
    return this.networkHelper.del<any>(environment.apiURL + this.disconnectLinkedAccountsUrl.replace(':providerId', providerId));
  }

  resendEmail(email:any){
    return this.networkHelper.post<any>(environment.apiURL + this.resendEmailUrl, {email});
  }
}
