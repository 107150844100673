import { Injectable , EventEmitter} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpService} from "./http.service";
import {Observable} from "rxjs";

@Injectable()
export class CommentsService {
  private getListUrl= '/comment/list';
  private addCommentUrl= '/comment/add';
  private updateCommentUrl= '/comment/:id';
  private blockCommentUrl= 'comment/block/:id';
  private unblockCommentUrl= 'comment/unblock/:id';
  private likeCommentUrl = '/comment/like/:id';
  private dislikeCommentUrl = '/comment/dislike/:id';


  constructor(private networkHelper: HttpService) { }

  getItems(pagination: any, filter: any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.getListUrl, {...pagination, ...filter});
  }

  add(data: any): Observable<any> {
    return this.networkHelper.post(environment.apiURL +
      this.addCommentUrl, data);
  }

  delete(id: any): Observable<any> {
    return this.networkHelper.del(environment.apiURL +
      this.updateCommentUrl.replace(':id', id));
  }

  getById(id: any): Observable<any> {
    return this.networkHelper.get(environment.apiURL +
      this.updateCommentUrl.replace(':id', id));
  }

  update(id: any, data: any): Observable<any> {
    return this.networkHelper.put(environment.apiURL +
      this.updateCommentUrl.replace(':id',id), data);
  }

  like(id: any): Observable<any> {
    return this.networkHelper.put(environment.apiURL + this.likeCommentUrl.replace(':id',id), {});
  }

  dislike(id: any): Observable<any> {
    return this.networkHelper.put(environment.apiURL + this.dislikeCommentUrl.replace(':id',id), {});
  }

  block(id: any): Observable<any> {
    return this.networkHelper.put(environment.apiURL + this.blockCommentUrl.replace(':id',id), {});
  }

  unblock(id: any): Observable<any> {
    return this.networkHelper.put(environment.apiURL + this.unblockCommentUrl.replace(':id',id), {});
  }


}
