import { Injectable } from '@angular/core';
import {HttpService} from './http.service';
import {environment} from '../../environments/environment';

@Injectable()
export class GameService {

  constructor(private networkHelper: HttpService) { }


  private searchUrl = '/game/search';
  private getListUrl = '/game/list';
  private getByIdUrl = '/game/:id';
  private getPlayersUrl = '/game/:id/players';
  private getOnboardingUrl = '/game/onboarding';
  private getOnboardingLocalizationUrl = '/localization/onboarding';

  search(filter: any): any {
    return this.networkHelper.get<any>(environment.apiURL + this.searchUrl, filter);
  }

  getItems(query: any = {}): any {
    return this.networkHelper.get<any>(environment.apiURL + this.getListUrl, query);
  }
  getById(id: any){
    return this.networkHelper.get<any>(environment.apiURL + this.getByIdUrl.replace(':id', id));
  }
  getPlayers(id: string, query: any){
    return this.networkHelper.get<any>(environment.apiURL + this.getPlayersUrl.replace(':id', id), query);
  }

  getForOnboarding(){
    return this.networkHelper.get<any>(environment.apiURL + this.getOnboardingUrl);
  }

  getForOnboardingLocalization(){
    return this.networkHelper.get<any>(environment.apiURL + this.getOnboardingLocalizationUrl);
  }

}
