import {Component, ElementRef, inject, Inject, InjectionToken, Injector, PLATFORM_ID, Renderer2, ViewEncapsulation} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "./services/storage.service";

import {appConstants} from "./constants";
import {UserService} from "./services/user.service";
import {Title} from "@angular/platform-browser";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {environment} from "../environments/environment";
import {DataLayerService} from "./helpers/dataLayerService";
import {DatePipe, isPlatformBrowser, isPlatformServer} from '@angular/common';
import {MeService} from "./services/me.service";
import {WindowRef} from "./helpers/windowRef.service";
import {CookieService} from "ngx-cookie-service";
import {CURRENCY_HEADER, LANGUAGE_HEADER} from "./models/server.injects";
import {EventManagerService} from "./services/event-manager.service";
import { GameService } from './services/game.service';
declare const gapi: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  title = 'Cybfed ';
  test = 'Cybfed ';
  currentDate = new Date();
  @Inject(LANGUAGE_HEADER) private languageHeader: any;
  @Inject(CURRENCY_HEADER) private currencyHeader: any;
  public formattedDate = this.datePipe.transform(this.currentDate, 'yyyy');
  backendIsNotAvailable = environment.underConstruction;
  private localizations:any = [];
  // @ts-ignore
  tourSteps = [
    {
      stepId: 'step1',
      title: 'Welcome to my app!',
      text: 'This is my app. Click the button to continue the tour.',
      anchorId: 'my-button',
      alignment: 'center',
      enableBackdrop: true
    },
    {
      stepId: 'step2',
      title: 'Another step',
      text: 'This is another step in the tour.',
      anchorId: 'my-other-element',
      alignment: 'bottom',
      enableBackdrop: true
    }
  ];
  constructor(private translate: TranslateService,
              private service: UserService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private meService: MeService,
              private _dataLayerService: DataLayerService,
              private datePipe: DatePipe,
              private titleDoc:Title,
              private cookieService: CookieService,
              private gameService: GameService,
              private injector: Injector,
              @Inject(PLATFORM_ID) private platformId: any,
              private windowRef: WindowRef) {
    StorageService.platformId = platformId;
    StorageService.windowRef = windowRef;
    if(isPlatformServer(platformId)){
      this.languageHeader = this.injector.get(LANGUAGE_HEADER);
      this.currencyHeader = this.injector.get(CURRENCY_HEADER);
      this.translate.setDefaultLang(this.languageHeader);
      this.translate.use(this.languageHeader);
    }
    let referral = this.findGetParameter('referral');
    if(referral){
      StorageService.setItem('referral', referral);
    }
    // this.router.events.subscribe((event:any)=> { // subscribe to router events
    //   if (event instanceof NavigationEnd) //if our event is of our interest
    //   {
    //     this._dataLayerService.logPageView(event.url) //call our dataLayer service's page view method to ping home with the url value.
    //   }
    // });
    if(!environment.underConstruction){
      let user = StorageService.getJsonItem(StorageService.statics.currentUser);
      let profile = StorageService.getJsonItem(StorageService.statics.profileData);
      let lang = StorageService.getItem(StorageService.statics.lang);
      let currency = StorageService.getItem(StorageService.statics.currency);
      if(!lang || lang === '' || lang === 'null' || !currency || currency === '' || currency === 'null' || lang === 'undefined' || currency === 'undefined' || profile){
        if (profile && user) {
          let inData = this.checkIfInArray(profile.language, profile.currency);
          this.service.getTicketPrice(profile.currency).subscribe((data:any) => {
            this.setLocales(inData.lang, inData.currency, data.data.ticketPrice);
          });
          this.titleDoc.setTitle('Cybfed ESports Marketplace')
        } else {
          let isBrowser = isPlatformBrowser(platformId)
          if (this.languageHeader && !isBrowser) {
            lang = this.languageHeader;
          }
          if(this.currencyHeader && !isBrowser){
            currency = this.currencyHeader;
          }
          if (!this.currencyHeader || !this.languageHeader) {
            this.service.getLocales().subscribe((data:any) => {
              if(!data.hasError){
                let inData = this.checkIfInArray(data.data.lang, data.data.currency);
                this.setLocales(inData.lang, inData.currency, data.data.ticketPrice);
              } else {
                lang = 'en';
                currency = 'USD';
                this.setLocales(lang, currency, data.data.ticketPrice)
              }
            }, () => {
              translate.setDefaultLang('en');
              translate.use('en');
            });
          } else {
            this.service.getTicketPrice(currency).subscribe((data:any) => {
              this.setLocales(lang, currency, data.data.ticketPrice)
            });
          }
        }

      } else {
        let inData = this.checkIfInArray(lang, currency);
        this.service.getTicketPrice(currency).subscribe((data:any) => {
          this.setLocales(inData.lang, inData.currency, data.data.ticketPrice)
        });
      }
    }
    this.gameService.getForOnboardingLocalization().subscribe((data:any) => {
      this.localizations = data.data.localizations;
      const langs = [];
      const translatableObject:any = {};
      for(let key in this.localizations){
        langs.push({'lang': this.localizations[key].lang})
        translatableObject[this.localizations[key].lang] = '';
      }
      StorageService.setJsonItem(StorageService.statics.cache.regions, langs);
      StorageService.setJsonItem(StorageService.statics.cache.translationDefaultModel, translatableObject);
    })
  }


  setLocales(lang:any, currency:any, ticketPrice:any){
    StorageService.setItem(StorageService.statics.lang, lang);
    StorageService.setItem(StorageService.statics.currency, currency);
    StorageService.setItem(StorageService.statics.ticketPrice, ticketPrice);
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.cookieService.set('language', lang, 9999999);
    this.cookieService.set('currency', currency, 9999999);
  }

  checkIfInArray(lang: string , currency: string){
    // @ts-ignore
    if(!appConstants.Languages[lang]){
      lang = 'en';
    }
    // @ts-ignore
    if(!appConstants.Currencies[currency]){
      currency = 'USD'
    }
    return {lang, currency}
  }

  findGetParameter(parameterName:string) {
    var result = null,
      tmp = [];
    if(isPlatformBrowser(this.platformId)) {
      // Use the window reference: this.windowRef
      this.windowRef.nativeWindow.location.search
        .substr(1)
        .split("&")
        .forEach(function (item:any) {
          tmp = item.split("=");
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    }
    return result;
  }

  changeBackendAvailability(e:any){
    this.backendIsNotAvailable = false;
  }


  setLang(event: string){
    this.translate.setDefaultLang(event);
    this.translate.use(event);
    StorageService.setItem(StorageService.statics.lang, event);
    if(StorageService.getToken() && isPlatformBrowser(this.platformId)){

      this.meService.updateProfile({language:event}).subscribe(()=>{
        this.windowRef.nativeWindow.location.reload();
      })
    }else if(isPlatformBrowser(this.platformId)) {
      this.windowRef.nativeWindow.location.reload();
    }
  }


}
