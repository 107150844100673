import {Pipe, PipeTransform} from '@angular/core';
import {Observable, of, takeWhile, timer} from 'rxjs';
import {map} from "rxjs/operators";

@Pipe({
  name: 'timerClass'
})
export class TimerClassPipe implements PipeTransform {
  eventId: number;
  expired = false;

  constructor() {}

  /**
   * @param futureDate    should be in a valid Date Time format
   *                      e.g. YYYY-MM-DDTHH:mm:ss.msz
   *                      e.g. 2021-10-06T17:27:10.740z
   */
  public transform(futureDateUtc: string): Observable<string> {
    /**
     * Initial check to see if time remaining is in the future
     * If not, don't bother creating an observable
     */
    if (!futureDateUtc || this.getMsDiff(futureDateUtc) < 0) {
      return of('red');
    }

    return timer(0, 1000).pipe(
      takeWhile(() => !this.expired),
      map(() => {
        return this.msToTime(this.getMsDiff(futureDateUtc));
      })
    );
  }

  /**
   * Gets the millisecond difference between a future date and now
   * @private
   * @param   futureDateUtc: string
   * @returns number  milliseconds remaining
   */
    // Z converts to local time
  private getMsDiff = (futureDate: string): number => (+(new Date(futureDate).getTime()) - Date.now());


  /**
   * Converts milliseconds to the
   *
   * @private
   * @param msRemaining
   * @returns null    when no time is remaining
   *          string  in the format `HH:mm:ss`
   */
  private msToTime(msRemaining: number): string {
    if (msRemaining < 60000) {
      return 'red';
    } else if(msRemaining < 180000){
      return 'orange'
    } else {
      return 'green'
    }
  }

}
