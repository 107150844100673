import { Injectable } from '@angular/core';
import {HttpService} from './http.service';
import {Observable} from 'rxjs';
import {environment} from "../../environments/environment";

@Injectable()
export class TopupService {

    private addUrl = '/balance/topup/';
    private checkPaymentUrl = '/balance/topup/check/:provider/:paymentCode';
    private convertUrl = '/balance/convert/';

    constructor(private networkHelper: HttpService) { }

    add(data:any): Observable<any> {
        return this.networkHelper.post(environment.apiURL +
            this.addUrl, data);
    }

    convert(data:any): Observable<any> {
      return this.networkHelper.post(environment.apiURL +
        this.convertUrl, {tickets: data});
    }
    checkPayment(provider: any, paymentCode: any): Observable<any> {
      return this.networkHelper.post(environment.apiURL +
        this.checkPaymentUrl.replace(':provider', provider).replace(':paymentCode', paymentCode));
    }

    publisherConvert(data:any): Observable<any> {
      return this.networkHelper.post(environment.apiURL +
        this.convertUrl, data);
    }


}
