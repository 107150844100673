import {Injectable} from "@angular/core";
import {HttpService} from "./http.service";

import {environment} from "../../environments/environment";

@Injectable()
export class FileUploadService {

  private getAccountsListUrl:string = '/media/list';
  private createFileUrle:string = '/media/createFile';
  private updateUrl:string = '/media';
  private editUrl:string = '/media/file';
  private deleteUrl:string = '/media';


   constructor(private networkHelper: HttpService) { }

  getFilesList(data?:any){
    return this.networkHelper.get<any>(environment.apiURL + this.getAccountsListUrl,data);
  }

  getFileById(fileId:string){
    return this.networkHelper.get<any>(environment.apiURL + `${this.editUrl}/${fileId}`);
  }



  createFile(data:any):any {
    return this.networkHelper.post<any>(environment.apiURL + this.createFileUrle,data );
  }


  update(data:any,fileId:string): any {
    return this.networkHelper.put<any>(environment.apiURL + `${this.updateUrl}/${fileId}`, data);
  }

  delete(fileId:string):any {
    return this.networkHelper.del<any>(environment.apiURL + `${this.deleteUrl}/${fileId}`);
  }

}
