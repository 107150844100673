<small *ngIf="!customMessage && errors.length || (errors.length && concatError && concatError !== errors[0])" i class="form-text error text-muted">
  {{'error.' + errors[0] | translate }}
</small>

<small *ngIf="concatError && errors.length && errors[0] === concatError && customMessage" i class="form-text error text-muted">
  {{'error.' + errors[0] + '.' + customMessage | translate }}
</small>

<small *ngIf="!concatError && customMessage" i class="form-text error text-muted">
  {{customMessage | translate }}
</small>
