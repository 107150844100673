import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  NavigationEnd
} from '@angular/router';
import {Observable} from 'rxjs';
import {StorageService} from '../services/storage.service';
import { MeService } from '../services/me.service';
import { WindowRef } from '../helpers/windowRef.service';

@Injectable({
  providedIn: 'root'
})
export class CheckNickGuard implements CanActivate {
  constructor(private router: Router,
              private meService:MeService,
              @Inject(PLATFORM_ID) private platformId: any,
              private windowRef: WindowRef) {
    StorageService.platformId = platformId;
    StorageService.windowRef = windowRef;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = StorageService.getItem(StorageService.statics.token);
    if(token){
      let currentUser = StorageService.getJsonItem(StorageService.statics.currentUser);
      if(currentUser && (!currentUser.nickname || currentUser.nickname === '')){
        this.meService.getProfile().subscribe((data: any)=>{
          if (!data.hasError) {
            if (data.data.account.nickname && data.data.account.nickname !== ''){
              StorageService.clear();
            }else{
              if (this.router.url.toString() !== '/add-nickname' && !currentUser.nickname) {
                this.router.navigate(['/add-nickname']);
              }
              this.router.events.subscribe(value => {
                if (value instanceof NavigationEnd) {
                  let currentUser = StorageService.getJsonItem(StorageService.statics.currentUser)
                  if (this.router.url.toString() !== '/add-nickname' && !currentUser.nickname) {
                    this.router.navigate(['/add-nickname']);
                  }
                }
              });
            }
          }
        })
      }
    }

    return true;
  }
}
