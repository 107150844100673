import { Component, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';

import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
  selector: 'app-toast-waiting',
  styleUrls: ['toasty.component.scss'],
  templateUrl: `./toasty.component.html`,
  // encapsulation: ViewEncapsulation.None
})
export class WaitingToastyComopnent extends Toast implements OnChanges{
  // used for demo purposes
  undoString = 'undo';

  // constructor is only necessary when not using AoT
  constructor(
    override toastrService: ToastrService,
    override toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.options = this.options
  }

  action(event: Event) {

    event.stopPropagation();
    this.undoString = 'undid';
    this.toastPackage.triggerAction();
    return false;
  }
}
