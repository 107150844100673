<div class='toasty-item'>
  <div class="title" *ngIf="title" >
    <div [innerHTML]="title | safe: 'html'"></div>
    <progress *ngIf = "options.progressBar" [value]="options.progressBar" max="100"> </progress>
  </div>
  <div class="main-container">
    <div class="percent"  *ngIf = "options.progressBar" >
      {{options.progressBar | shortNumber}}%
    </div>
    <div>
      <div *ngIf="message" class="message">
        {{message | translate}}
      </div>
    </div>
  </div>
</div>

