import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { EventManagerService } from '../../../services/event-manager.service';
import {StorageService} from "../../../services/storage.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, OnDestroy {
  publisherToAccountLoading: any;
  publisherToAccountData:any = {};

  constructor(private eventManagerService:EventManagerService,
              private translate: TranslateService) {

  }

  ngOnInit(): void {
    this.eventManagerService.showPublisherToAccountLoadingEmitter.subscribe((data)=>{
      this.publisherToAccountLoading = true;
      this.publisherToAccountData = data
    })
    this.eventManagerService.hidePublisherToAccountLoadingEmitter.subscribe((data)=>{
      this.publisherToAccountLoading = false;
    })
  }

  ngOnDestroy() {

  }
}
