import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpService } from '../http.service';

@Injectable()
export class OrderService {

  private buyUrl = '/product/buy';
  private getMyOrdersUrl = '/product/myOrders/list';
  private getMyOldOrdersUrl = '/product/oldOrders/list';
  private rejectUrl = '/product/order/reject/:id';
  private acceptUrl = '/product/order/accept/:id';
  private confirmUrl = '/product/order/confirm/:id';
  private cancelUrl = '/product/order/cancel/:id';
  private getOrderUrl = '/product/order/:id';
  private disputeOrderUrl = '/product/order/dispute/:id';
  // private getCurrentOrdersUrl = '/product/currentOrders/list';


  ////


  constructor(private networkHelper: HttpService) {
  }

  getById(id: any): Observable<any> {
    return this.networkHelper.get(environment.apiURL +
      this.getOrderUrl.replace(':id', id));
  }
  buy(data: any): Observable<any> {
    return this.networkHelper.post(environment.apiURL +
      this.buyUrl, data);
  }

  getMyOrders(pagination: any, filter: any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.getMyOrdersUrl, { ...pagination, ...filter });
  }
  getMyOldOrders(pagination: any, filter: any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.getMyOldOrdersUrl, { ...pagination, ...filter });
  }
  reject(id: any,data:any): Observable<any> {
    return this.networkHelper.put(environment.apiURL +
      this.rejectUrl.replace(':id', id), data);
  }
  accept(id: any,data:any): Observable<any> {
    return this.networkHelper.put(environment.apiURL +
      this.acceptUrl.replace(':id', id), data);
  }
  confirm(id: any,data:any): Observable<any> {
    return this.networkHelper.put(environment.apiURL +
      this.confirmUrl.replace(':id', id), data);
  }
  cancel(id: any,data:any): Observable<any> {
    return this.networkHelper.put(environment.apiURL +
      this.cancelUrl.replace(':id', id), data);
  }
  dispute(id: any,data:any): Observable<any> {
    return this.networkHelper.put(environment.apiURL +
      this.disputeOrderUrl.replace(':id', id), data);
  }
  // getCurrentOrders(pagination: any, filter: any): Observable<any> {
  //   return this.networkHelper.get(environment.apiURL + this.getCurrentOrdersUrl, { ...pagination, ...filter });
  // }
  //
  // getById(id: any): Observable<any> {
  //   return this.networkHelper.get(environment.apiURL +
  //     this.getProductUrl.replace(':id', id));
  // }

  // getMyProducts(pagination: any, filter: any): Observable<any> {
  //   return this.networkHelper.get(environment.apiURL + this.getMyProductsUrl, { ...pagination, ...filter });
  // }

}
