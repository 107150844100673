import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-publisher-to-account-loader',
  templateUrl: './publisher-to-account-loader.component.html',
  styleUrls: ['./publisher-to-account-loader.component.scss']
})
export class PublisherToAccountLoaderComponent implements OnInit {
  @Input() imgUrl:any
  @Input() nickname:string;
  @Input() loading:boolean = false;


  constructor() { }


  ngOnInit(): void {
  }

}
