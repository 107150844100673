import { Injectable } from '@angular/core';
import {HttpService} from './http.service';
import {environment} from '../../environments/environment';

@Injectable()
export class GameAccountService {

  constructor(private networkHelper: HttpService) { }


  private getByGameUrl = '/game-account/by-gameId/:gameId';
  private saveGameAccountUrl = '/game-account/save/:gameId';

  getByGame(gameId: string): any {
    return this.networkHelper.get<any>(environment.apiURL + this.getByGameUrl.replace(':gameId', gameId));
  }

  saveGameAccount(gameId: string, data: any): any {
    return this.networkHelper.post<any>(environment.apiURL + this.saveGameAccountUrl.replace(':gameId', gameId), data);
  }


}
