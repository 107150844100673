import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HttpService} from "./http.service";
import {environment} from "../../environments/environment";

@Injectable()
export class TournamentService {

  private getSettingInfoUrl = '/tournament/:id';
  private getPopupInfoByIdUrl = '/tournament/info/:id';
  private getGroupUrl = '/tournament/group/:id';
  private getMyChallengesUrl = '/tournament/my-challenges';
  private getStagesUrl = '/tournament/stages';
  private getTournaments = '/tournament/list';
  private getMainEventUrl = '/tournament/main-event';

  ////

  constructor(private networkHelper: HttpService) {
  }

  getItems(pagination:any, filter:any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.getTournaments,{...pagination, ...filter});
  }

  getMainEvent(){
    return this.networkHelper.get(environment.apiURL + this.getMainEventUrl);
  }
  getById(id: any): Observable<any> {
    return this.networkHelper.get(environment.apiURL +
      this.getSettingInfoUrl.replace(':id', id));
  }

  getMyChallenges(query: any){
    return this.networkHelper.get(environment.apiURL +
      this.getMyChallengesUrl, query);
  }
  getStages(query: any){
    return this.networkHelper.get(environment.apiURL +
      this.getStagesUrl, query);
  }
  getPopupInfoById(id: any): Observable<any> {
    return this.networkHelper.get(environment.apiURL +
      this.getPopupInfoByIdUrl.replace(':id', id));
  }

  getByGroupId(id: any): Observable<any> {
    return this.networkHelper.get(environment.apiURL +
      this.getGroupUrl.replace(':id', id));
  }

  delete(id: any): Observable<any> {
    return this.networkHelper.get(environment.apiURL +
      this.getSettingInfoUrl.replace(':id', id));
  }
}
