
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {HttpService} from "./http.service";
import {environment} from "../../environments/environment";

@Injectable()
export class ParticipateService {

    private participateUrl = '/participate';
    private participateListUrl = '/participate/list';
    private participateTeamListUrl = '/participate/team-list';
    private participateUpdateUrl = '/participate/{{id}}';
    private participateTaskListUrl = '/participate/check-task-list/{{forTable}}/{{forId}}';
    private getMyParticipateUrl = '/participate/my-participate';
    private subscribeUrl = '/participate/nextTournament/{{id}}';
    ////

    constructor(private networkHelper: HttpService) { }

    participateList(pagination: {} | undefined, filter: {} | null | undefined): Observable<any> {
      return this.networkHelper.get(environment.apiURL +
        this.participateListUrl,{...pagination, ...filter});
    }

    participateTeamList(pagination: {} | undefined, filter: {} | null | undefined): Observable<any> {
      return this.networkHelper.get(environment.apiURL +
        this.participateTeamListUrl,{...pagination, ...filter});
    }

    participate(forTable:any, forId: any,  enterPaymentMethod: any, teamInvitationId: string | null = null): Observable<any> {
        return this.networkHelper.post(environment.apiURL +
            this.participateUrl,{forId,forTable,enterPaymentMethod, teamInvitationId});
    }

    refundParticipant(id:any): Observable<any> {
      return this.networkHelper.del(environment.apiURL +
        this.participateUpdateUrl.replace('{{id}}', id));
    }

    subscribe(id:any): Observable<any> {
      return this.networkHelper.post(environment.apiURL +
        this.subscribeUrl.replace('{{id}}', id),{});
    }

    getMyParticipate(filter: any = {}): Observable<any> {
      return this.networkHelper.get(environment.apiURL +
        this.getMyParticipateUrl, filter);
    }

    checkTaskList(forTable:any, forId:any): Observable<any> {
      return this.networkHelper.get(environment.apiURL +
        this.participateTaskListUrl.replace('{{forTable}}', forTable).replace('{{forId}}', forId));
    }

    doTaskAction(forTable:any, forId:any, data:any): Observable<any> {
      return this.networkHelper.put(environment.apiURL +
        this.participateTaskListUrl.replace('{{forTable}}', forTable).replace('{{forId}}', forId), data);
    }
}
