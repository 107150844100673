<div class="main-container">
  <div class="w-30">
    <div class="title">{{'add_nickname' | translate}}</div>



    <app-custom-input [control]="addNicknameForm.controls['nickname']"
                      [required]="true"
                      class="bg-transparent white"
                      labelClass="green"
                      labelText="{{'nickname'| translate}}">
    </app-custom-input>
    <app-validation-error [control]="addNicknameForm.controls['nickname']" [customMessage] = "'nickname'" [concatError] ="'pattern'"></app-validation-error>

    <div class="text-align-center mt-1-0">
      <app-primary-button (onClick)="onSubmit()" className="pr-5-0 pl-5-0" text="{{'save'| translate}}"></app-primary-button>
    </div>

  </div>

</div>
