import { Injectable , EventEmitter} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpService} from "./http.service";
import {Observable} from "rxjs";

@Injectable()
export class InviteService {
  private addUrl = '/invite'
  private cancelUrl = '/invite/cancel/:id'
  private acceptUrl = '/invite/accept/:id'
  private deleteUrl = '/invite/:id'
  private findMyPendingInvitesUrl = '/invite/my-pending'

  constructor(private networkHelper: HttpService) { }

  add(data: any): Observable<any> {
    return this.networkHelper.post(environment.apiURL + this.addUrl, data);
  }

  findMyPendingInvites(): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.findMyPendingInvitesUrl);
  }

  cancel(inviteId: string): Observable<any> {
    return this.networkHelper.post(environment.apiURL + this.cancelUrl.replace(':id', inviteId), {});
  }
  accept(inviteId: string): Observable<any> {
    return this.networkHelper.post(environment.apiURL + this.acceptUrl.replace(':id', inviteId), {});
  }
  delete(inviteId: string): Observable<any> {
    return this.networkHelper.del(environment.apiURL + this.deleteUrl.replace(':id', inviteId));
  }

}
