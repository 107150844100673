<div class='form'>
  <input #input [autocomplete]='autocomplete' [type]='type' [formControl]='control' (change)='change($event)'
         (input)='oninput($event)' (ngModelChange)='onModelChange($event)' [attr.disabled]="disabled ? '' : null"
         id='text' [value]="value || ''" name='text' class='input {{class}}' [required]='required' />
  <label for='text' class='label-name {{labelClass}}'>
    <span class='content-name'>
      {{labelText}} {{required ? '*' : ''}}
    </span>

  </label>
  <div (click)='showText()' class='eye' *ngIf="type === 'password'">
    <i *ngIf="input.type === 'text'" class='fas fa-eye'></i>
    <i *ngIf="input.type === 'password'" class='fas fa-eye-slash'></i>
  </div>
</div>
<div class='error' *ngIf='hasError'> {{'error.required' | translate}} </div>



