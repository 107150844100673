

import { Injectable } from '@angular/core';
import {HttpService} from './http.service';
import {Observable} from 'rxjs';
import {environment} from "../../environments/environment";
@Injectable()
export class PackageService {

    private getItemsUrl= '/package/list';
    ////

    constructor(private networkHelper: HttpService) { }

    getItems(pagination:any, filter:any): Observable<any> {
        return this.networkHelper.get(environment.apiURL + this.getItemsUrl, {...pagination, ...filter});
    }

}
