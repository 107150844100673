import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {EventManagerService} from '../../../services/event-manager.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-guest',
  templateUrl: './guest.component.html',
  styleUrls: ['./guest.component.scss']
})
export class GuestComponent implements OnInit {

  constructor(private router: Router,
              private eventService: EventManagerService,
              private translate: TranslateService,
              @Inject(PLATFORM_ID) private platformId: any,) { }

  ngOnInit(): void {

  }


}
