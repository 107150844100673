import { Injectable , EventEmitter} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpService} from "./http.service";
import {Observable} from "rxjs";

@Injectable()
export class VoteService {
  private addUrl= '/vote';
  private addInRoomUrl = '/vote/in-room/:roomId';
  private votingUrl = '/vote/:id/:optionId';
  private checkVotedUrl = '/vote/check-voted/:id'

  constructor(private networkHelper: HttpService) { }

  add(data: any): Observable<any> {
    return this.networkHelper.post(environment.apiURL +
      this.addUrl, data);
  }
  addInRoom(data: any, roomId: string): Observable<any> {
    return this.networkHelper.post(environment.apiURL +
      this.addInRoomUrl.replace(':roomId', roomId), data);
  }
  voting(id: string, optionId: string){
    return this.networkHelper.post(environment.apiURL +
      this.votingUrl.replace(':id', id).replace(':optionId', optionId), {});
  }
  checkVoted(id: string){
    return this.networkHelper.get(environment.apiURL +
      this.checkVotedUrl.replace(':id', id));
  }
}
