import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-under-construction',
  templateUrl: './under-construction.component.html',
  styleUrls: ['./under-construction.component.scss']
})
export class UnderConstructionComponent implements OnInit {
  @Input() tournament: any;
  @Input() link: any;
  @Output() fireChangeAction: EventEmitter<any>;
  public tournamentData: any;
  public statusColor: string;
  constructor() {
    this.fireChangeAction = new EventEmitter<any>();
  }

  ngOnInit(): void {

  }

  openSite(){
    this.fireChangeAction.emit();
  }

}
