import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {appConstants} from "../../constants";
import {AuthenticationService} from '../../services/authentication.service';
import {StorageService} from "../../services/storage.service";
import {PopupHelperService} from "../../services/popup-helper.service";
import {isPlatformBrowser} from "@angular/common";
import {WindowRef} from "../../helpers/windowRef.service";

@Component({
  selector: 'app-auth-providers',
  templateUrl: './auth-providers.component.html',
  styleUrls: ['./auth-providers.component.scss']
})
export class AuthProvidersComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private router: Router,
              private popupService: PopupHelperService,
              @Inject(PLATFORM_ID) private platformId: any,
              private windowRef: WindowRef) {
  }
  provider: any = null;
  queryParams: any = null
  errorMessage: String;
  loginData: any = null;


  ngOnInit(): void {
    this.provider = this.route.snapshot.paramMap.get('provider');

    this.queryParams = (isPlatformBrowser(this.platformId)) ? this.windowRef.nativeWindow.location.href.split("?")[1]:'';
    if(this.queryParams.search('error') !== -1){
      this.router.navigate(['/auth/signin']);
      return
    }
    let token = StorageService.getItem(StorageService.statics.token);
    let referral = StorageService.getItem('referral');
    if (this.provider === appConstants.PROVIDERS.FACEBOOK) {
      this.authenticationService.callbackFb(this.queryParams,{token: token, referral}).subscribe((data: any) => {
        this.providerLogin(data)
      })
    }else if(this.provider === appConstants.PROVIDERS.VK){
      this.authenticationService.callbackVk(this.queryParams, {token: token, referral}).subscribe((data: any) => {
        this.providerLogin(data)
      })
    }else if(this.provider === appConstants.PROVIDERS.STEAM){
      this.authenticationService.callbackSteam(this.queryParams, {token: token, referral}).subscribe((data: any) => {
        this.providerLogin(data)
      })
    }else if(this.provider === appConstants.PROVIDERS.TWITCH){
      this.authenticationService.callbackTwitch(this.queryParams, {token: token, referral}).subscribe((data: any) => {
        this.providerLogin(data)
      })
    }else if(this.provider === appConstants.PROVIDERS.GOOGLE){
      this.authenticationService.callbackGoogle(this.queryParams, {token: token, referral}).subscribe((data: any) => {
        this.providerLogin(data)
      })
    }else if(this.provider === appConstants.PROVIDERS.DISCORD){
      if(this.getParameterByName('state', this.queryParams) === 'clan-created'){
        this.authenticationService.callbackDiscordClan(this.queryParams, {token: token, referral}).subscribe((data: any) => {
          this.providerLogin(data)
        })
      }else{
        this.authenticationService.callbackDiscord(this.queryParams, {token: token, referral}).subscribe((data: any) => {
          this.providerLogin(data)
        })
      }

    }
  }

  getParameterByName(name:any, url:any) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  providerLogin(data: any){
    if (!data.hasError) {
      let backRedirectUrl = data.data.redirectUrl;
      let redirectParams = data.data.state;
      if(data.data.connected ) {
        if(backRedirectUrl){
          StorageService.removeItem(StorageService.statics.lastPage);
          this.router.navigate([backRedirectUrl],{
            queryParams: { state: redirectParams }
          }).then(() => {

          });
        }else{
          this.router.navigate(['/me/connected-accounts']);
        }
      } else {
        StorageService.setJsonItem(StorageService.statics.currentUser, data.data.loginData);
        StorageService.setItem(StorageService.statics.token, data.data.loginData.token);
        let currentLang = StorageService.getItem(StorageService.statics.lang);

        if(currentLang !== data.data.loginData.language){
          StorageService.setItem(StorageService.statics.lang, data.data.loginData.language);

          let redirectUrl = StorageService.getItem(StorageService.statics.lastPage);
          if(backRedirectUrl){
            StorageService.removeItem(StorageService.statics.lastPage);
            this.router.navigate([backRedirectUrl],{
              queryParams: { state: redirectParams }
            }).then(() => {

            });
          } else if(redirectUrl){
            StorageService.removeItem(StorageService.statics.lastPage);
            this.router.navigate([redirectUrl]).then(() => {
              if(isPlatformBrowser(this.platformId)) {
                this.windowRef.nativeWindow.location.reload();
              }
            });
          }else{
            this.router.navigate(['/']).then(() => {
              if(isPlatformBrowser(this.platformId)) {
                this.windowRef.nativeWindow.location.reload();
              }
            });
          }
        }else {
          let redirectUrl = StorageService.getItem(StorageService.statics.lastPage);
          if(backRedirectUrl){
            StorageService.removeItem(StorageService.statics.lastPage);
            this.router.navigate([backRedirectUrl],{
              queryParams: redirectParams
            }).then(() => {

            });
          } else if(redirectUrl){
            StorageService.removeItem(StorageService.statics.lastPage);
            this.router.navigate([redirectUrl]);
          }else{
            this.router.navigate(['/']);
          }
        }
      }
    } else {
      if(data.errorMessages[0].msg === "thisProfileConnectedOtherAccount"){
        this.router.navigate(['/me/connected-accounts'], { queryParams: {errorMessage: data.errorMessages[0].msg }});
      }else {
        this.router.navigate(['/auth/signin']);
      }
    }
  }



}
