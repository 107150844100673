<!--<div class="main-container">-->
<!--  <div class="w-30">-->
<!--    <div class="text-align-center sign-up-with-facebook mt-1-0 mb-1-0">-->
<!--      <h3 class="success">Տեխնիկական աշխատանքներ</h3>-->
<!--      <h5 class="success">Հայցում ենք ձեր ներողամտությունը</h5>-->
<!--      <h6 class="text-align-center success" >-->
<!--        Համակարգում կատարվում են տեխնիկական աշխատանքներ: Շուտով կլինենք հասանելի: Տեղեկացված լինելու համար կարող եք միանալ մեր <a>TELEGRAM</a> ալիքին-->
<!--      </h6>-->
<!--      <br/>-->
<!--      <app-primary-button [url]="'https://t.me/cybfedNetwork'" text="Միանալ TELEGRAM ալիքին"></app-primary-button>-->
<!--    </div>-->

<!--    <div class="display-flex justify-space-between pt-1-0 pb-1-0">-->
<!--    </div>-->

<!--  </div>-->

<!--</div>-->


<div class="main-container">
  <div class="w-30">
    <div class="text-align-center sign-up-with-facebook mt-1-0 mb-1-0">
      <h3 class="success" style="color:white">Հարգելի օգտատեր</h3>
      <h5 class="success" style="color:white">Հայցում ենք ձեր ներողամտությունը կայքում կատարվում են տեխնիկական աշխատանքներ:</h5>
      <h6 class="text-align-center success" >
        Կայքի թարմացումներին տեղեկանալու համար: Միացեք <a>TELEGRAM</a>:
      </h6>
      <br/>

<!--      <p>Մինչ այդ՝ թարմացրեք ձեր Pubg mobile վերսիան և լինենք լավատես</p>-->
<!--      <p>Համբերություն բոլորիս:</p>-->
      <app-primary-button [url]="'https://t.me/cybfedNetwork'" text="Միանալ TELEGRAM ալիքին"></app-primary-button>

<!--      <p class="mt-1-0 text-align-center success">-->
<!--        Եթե ունեիք կուտակված գումար դուք կարող եք կանխիկացման հայտ բացել՝ այս անգամ առանձ լիցքավորելու:-->
<!--        Գումարները կվճարվեն 24 ժամվա ընթացքում-->
<!--      </p>-->
<!--      <a [routerLink]="['/me/withdraw']" (click)="openSite()" class="mt-1-0 text-white">Կանխիկացման հայտ</a>-->
    </div>

    <div class="display-flex justify-space-between pt-1-0 pb-1-0">
    </div>

  </div>

</div>
