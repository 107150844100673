import { Injectable } from '@angular/core';
import {appConstants} from "../../constants";
import {EventManagerService} from "../event-manager.service";
import {SocketConnect} from "./socket-connect";

@Injectable({
  providedIn: 'root'
})
export class SocketMainService {


  constructor(public socket: SocketConnect,
              private eventManager: EventManagerService) {
    this.socket.on('connect', () => console.log('Connected'));
    this.socket.on('disconnect', () => {console.log('Disconnect');});
  }


  joinRoom(eventType = appConstants.EVENTTYPES.USER, forId: any = null){
    this.socket.emit('join', {eventType, forId});
  }

  unJoinRoom(eventType = appConstants.EVENTTYPES.USER, forId: any = null){
    this.socket.emit('leave', {eventType, forId});
  }

  listenToEventWithCallback(actionType: any, eventType: any, callback:any){
    this.socket.on(actionType +'_' + eventType , callback);
  }

  listenToEventWithEventManager(actionType: string){
    this.socket.on(actionType, (data: any) => {
      this.eventManager.socketDataListener.emit(data);
    })
  }

  disconnect() {
    this.socket.disconnect();
  }
}
