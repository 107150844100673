import { Injectable , EventEmitter} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpService} from "./http.service";
import {Observable} from "rxjs";

@Injectable()
export class CrowingProcessService {
  private getMyProcessUrl = '/crawler-process/my-process'
  private getItemsUrl = '/crawler-process/list'
  private deleteUrl = '/crawler-process/:id'
  private addUrl = '/crawler-process'
  private agreeToPlayUrl = '/crawler-process/agree-to-play/:id'
  private giveUpPlayUrl = '/crawler-process/give-up-play/:id'
  private goToPlayWithRandomPlayersModeUrl = '/crawler-process/go-to-play-with-random-players-mode/:code'

  constructor(private networkHelper: HttpService) { }

  getItems(query: any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.getItemsUrl, query);
  }
  getMyProcess(query: any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.getMyProcessUrl, query);
  }
  delete(id: string): Observable<any>{
    return this.networkHelper.del(environment.apiURL + this.deleteUrl.replace(':id', id))
  }
  add(data: any): Observable<any>{
    return this.networkHelper.post(environment.apiURL + this.addUrl, data)
  }

  agreeToPlay(processId: string){
    return this.networkHelper.post(environment.apiURL + this.agreeToPlayUrl.replace(':id', processId), {})
  }
  giveUpPlay(processId: string){
    return this.networkHelper.post(environment.apiURL + this.giveUpPlayUrl.replace(':id', processId), {})
  }

  goToPlayWithRandomPlayersMode(code: string){
    return this.networkHelper.post(environment.apiURL + this.goToPlayWithRandomPlayersModeUrl.replace(':code', code), {})
  }
}
