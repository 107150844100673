<div class= "main-container">
  <div class="herder">
    <a class="main-logo" [routerLink]="'/'">
      <img class="logo" src="/assets/image/cybfed-logo.png" alt="">
    </a>
  </div>

  <div class="iframe-container">
    <iframe class="iframe-standart" [src]="openUrl"></iframe>
  </div>

</div>
