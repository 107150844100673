import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.scss']
})
export class CustomInputComponent implements OnInit {

  @Output() changeInput = new EventEmitter();
  @Output() modelChange = new EventEmitter();
  @Output() inputFunction = new EventEmitter();
  @Input() type: 'text'| string;
  @Input() pattern: any;
  // @Input() formControl: FormControl | any;

  @Input() control: FormControl = new FormControl()
  @Input() inputType: 'input'| 'textArea'| string;
  @Input() labelText: string;
  @Input() placeHolder: string;
  @Input() value: string | undefined;
  @Input() id: string;
  @Input() class: string;
  @Input() inputClass: string;
  @Input() labelClass: string;
  @Input() hasError: boolean = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() autocomplete: string = 'off';
  @ViewChild('input') input: ElementRef<HTMLInputElement>;



  constructor() { }

  ngOnInit(): void {

  }

  change(event:any):void{
    this.changeInput.emit(event.target.value)
  }
  onModelChange(value: any){
    this.modelChange.emit(value)
  }
  oninput(value: any){
    this.inputFunction.emit(value)
  }
  showText():void{

    if((this.input.nativeElement as HTMLInputElement).type === 'password'){
      (this.input.nativeElement as HTMLInputElement).type = 'text'
    }else{
      (this.input.nativeElement as HTMLInputElement).type = 'password'
    }
  }



}
