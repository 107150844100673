import {EventManagerService} from "./event-manager.service";


export class ResponseHelperService {

  public successCode = 200;
  public errorCode = 400;
  public errorCode2 = 500;
  public phoneVerificationFalse = 495;

  public response:any;
  public messages:any;
  public data:any;
  public errorMessages :any;
  public hasError;

  constructor(res: any = null) {

    this.response = res;
    const currentStatus: number = res.status;
    this.hasError = false;
    switch (currentStatus) {
      case this.successCode :
        this.catchForSuccess(res.result, res.message);
        break;
      case this.errorCode :
        this.catchForError(res.message);
        this.hasError = true;
        break;
      case this.errorCode2 :
        this.catchForError(res.message);
        this.hasError = true;
        break;
      case this.phoneVerificationFalse :
        this.catchForError(res.message);
        this.hasError = true;
        break;
      default:
        break;
    }
  }


  catchForSuccess(data:any, message:string | []): void {

    if (!data || data === undefined) {
      data = [];
    }
    if (!message || message === undefined) {
      message = [];
    }

    this.data = data;
    if (message.length > 0) {
      this.messages = message;
    }


    this.hasError = false;
  }

  catchForError(messages:string | []): void {
    if (!messages || messages === undefined) {
      messages = [];
    }
    this.errorMessages = messages;
  }
}








