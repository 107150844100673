import {Pipe, PipeTransform} from '@angular/core';
import {appConstants} from "../../../constants";

@Pipe({
  name: 'platforms'
})
export class PlatformsPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let icons = '';
    for(let key in value){
      switch (value){
        case appConstants.PLATFORMS.IOS:
          break;
        case appConstants.PLATFORMS.ANDROID:
          break;
        case appConstants.PLATFORMS.PS4:
          break;
        case appConstants.PLATFORMS.PS5:
          break;
        case appConstants.PLATFORMS.WEB:
          break;
        case appConstants.PLATFORMS.NINTENDO:
          break;
        case appConstants.PLATFORMS.OFFLINE:
          break;
        case appConstants.PLATFORMS.WINDOWS:
          break;
        case appConstants.PLATFORMS.XBOX:
          break;
        case appConstants.PLATFORMS.XBOXXS:
          break;
        case appConstants.PLATFORMS.OTHER:
          break;
        default:
          break;
      }
    }

    return value;
  }

}
