import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'urlify'
})
export class UrlifyPipe implements PipeTransform {
  transform(str: any): any {
    var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return str.replace(urlRegex, function(url: any) {
      return '<a href="' + url + '" target="_blank">' + url + '</a>';
    });
   // console.log('str',str)
    //return str
  }
}
