import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthComponent} from './theme/layout/auth/auth.component';
import {AuthGuard} from './guards/auth.guard';
import {AuthProvidersComponent} from "./pages/auth-providers/auth-providers.component";
import {AddNicknameComponent} from "./pages/add-nickname/add-nickname.component";
import {
  AuthActivateUserTokenComponent
} from "./pages/authentication/auth-activate-user-token/auth-activate-user-token.component";
import {GameBattleComponent} from "./pages/game-battle/game-battle.component";
import { CheckNickGuard } from './guards/check-nick.guard';
import {GuestComponent} from "./theme/layout/guest/guest.component";

const routes: Routes = [
  {
    path: 'activate-token/:token',
    component: AuthActivateUserTokenComponent,
  },
  {
    path: 'add-nickname',
    canActivate: [AuthGuard],
    component: AddNicknameComponent
  },
  {
    path: 'game-battle/:forId/:forTable',
    canActivate: [AuthGuard, CheckNickGuard],
    component: GameBattleComponent
  },
  {
    path: 'auth-provider/:provider',
    component: AuthProvidersComponent
  },

  {
    path: 'widgets',
    component: GuestComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages-full-screen/full-screen.module').then(module => module.FullScreenModule)
      },
    ]
  },
  {
    path: '',
    canActivate: [CheckNickGuard],
    component: AuthComponent,
    children: [
      // {
      //   path: 'guest',
      //   canActivate: [NotAuthGuard],
      //   loadChildren: () => import('./theme/layout/auth/logedout/logedout.module').then(module => module.LogedoutModule)
      // },
      // {
      //   path: 'onboarding',
      //   loadChildren: () => import('./theme/layout/auth/onboarding/onboarding.module').then(module => module.OnboardingModule)
      // },
      {
        path: 'publisher',
        canActivate: [AuthGuard],
        loadChildren: () => import('./theme/layout/auth/publisher/publisher-layout.module').then(module => module.PublisherLayoutModule)
      },
      {
        path: '',
        loadChildren: () => import('./theme/layout/auth/logedin/logedin.module').then(module => module.LogedinModule)
      }

    ]
  },
  {
    path: '404',
    loadChildren: () => import('./pages/page-not-found/page-not-found.module').then(module => module.PageNotFoundModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
