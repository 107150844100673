import { Injectable } from '@angular/core';
import {HttpService} from './http.service';
import {environment} from '../../environments/environment';

@Injectable()
export class PublisherService {

  constructor(private networkHelper: HttpService) { }


  private createUrl = '/publisher/create';
  private getPublisherUrl = '/publishers/:id/dashboard';
  private listUrl = '/publishers/list';

  private myListUrl = '/me/get-publisher-info'


  private subscribeUrl = '/publishers/follow/'
  private unsubscribeUrl = '/publishers/unfollow/'


  private publisherInfoUrl = '/publishers/:id'

  private donateUrl = '/publishers/:id/donate'

  create(publisherData: any): any {
    return this.networkHelper.post<any>(environment.apiURL + this.createUrl, publisherData);
  }
  getPublisher(id: string): any {
   return this.networkHelper.get<any>(environment.apiURL + this.getPublisherUrl.replace(':id', id));
  }
  list(query: any): any {
    return this.networkHelper.get<any>(environment.apiURL + this.listUrl , query);
  }

  myList(query: any): any {
    return this.networkHelper.get<any>(environment.apiURL + this.myListUrl , query);
  }
  subscribe(publisherId: string): any {
    return this.networkHelper.put<any>(environment.apiURL + this.subscribeUrl + publisherId, {})
  }
  unsubscribe(publisherId: string): any {
    return this.networkHelper.put<any>(environment.apiURL + this.unsubscribeUrl + publisherId, {})
  }

  getPublisherForClient(publisherId: string): any {
    return this.networkHelper.get<any>(environment.apiURL + this.publisherInfoUrl.replace(':id', publisherId), {forClient: true})
  }
  donatePublisher(publisherId: string, amountOfTickets:any){
    return this.networkHelper.put<any>(environment.apiURL + this.donateUrl.replace(':id', publisherId), {tickets: amountOfTickets})
  }
}
