import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-mask-loader',
  templateUrl: './mask-loader.component.html',
  styleUrls: ['./mask-loader.component.scss']
})
export class MaskLoaderComponent implements OnInit {

  constructor() { }

  //TODO: change style
  ngOnInit(): void {
  }

}
