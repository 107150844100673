import {NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { GuestComponent } from './theme/layout/guest/guest.component';
import {HttpService} from './services/http.service';
import {StorageService} from './services/storage.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {AuthProvidersComponent} from "./pages/auth-providers/auth-providers.component";
import { AddNicknameComponent } from './pages/add-nickname/add-nickname.component';
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from "ngx-google-analytics";
import {SharedGuestModule} from "./theme/shared/shared-guest.module";
import {TransferHttpCacheModule} from '@nguniversal/common';
import {SocketMainService} from "./services/socket/socket-main-service";
import {ResizeService} from "./services/resize.service";
import {SizeDetectorComponent} from "./theme/shared/components/size-detector/size-detector.component";
import {UnderConstructionComponent} from "./theme/shared/components/under-construction-component/under-construction.component";
import {GameBattleComponent} from "./pages/game-battle/game-battle.component";
import {TopupCallbacksComponent} from "./pages/topup-callbacks/topup-callbacks.component";
import { EventManagerService } from './services/event-manager.service';
import { SharedModule } from './theme/shared/shared.module';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {MeService} from "./services/me.service";
import {WindowRef} from "./helpers/windowRef.service";
import {DataLayerService} from "./helpers/dataLayerService";
import {environment} from "../environments/environment";
import { GameService } from './services/game.service';
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, environment.portalUrl  + '/assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    UnderConstructionComponent,
    AppComponent,
    AuthComponent,
    GuestComponent,
    AuthProvidersComponent,
    SizeDetectorComponent,
    AddNicknameComponent,
    GameBattleComponent,
    TopupCallbacksComponent
  ],
  imports: [
    TranslateModule.forRoot({
      defaultLanguage: StorageService.getItem(StorageService.statics.lang) || 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserModule.withServerTransition({ appId: 'cybfed' }),
    TransferHttpCacheModule,
    AppRoutingModule,
    NgxGoogleAnalyticsModule.forRoot(environment.google.idChannel),
    NgxGoogleAnalyticsRouterModule,
    BrowserAnimationsModule,

    HttpClientModule,
    SharedGuestModule,
    SharedModule

  ],
  providers: [
    HttpService,
    ResizeService,
    MeService,
    WindowRef,
    DataLayerService,
    SocketMainService,
    StorageService,
    EventManagerService,
    GameService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


// platformBrowserDynamic().bootstrapModule(AppModule);
