import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ImageCropperModule} from "ngx-image-cropper";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CustomCheckboxComponent} from "./components/custom-checkbox/custom-checkbox.component";
import {CustomModalComponent} from "./components/custom-modal/custom-modal.component";
import {ValidationErrorComponent} from "./components/app-validation-error/validation-error.component";
import {PrimaryButtonComponent} from "./components/primary-button/primary-button.component";
import {HttpClientModule} from "@angular/common/http";
import {TranslateModule} from "@ngx-translate/core";
import {LabelComponent} from "./components/label/label.component";
import {CurrencyPipe} from "./pipes/currency.pipe";
import {KeyobjectPipe} from "./pipes/keyobject.pipe";
import {PlatformsPipe} from "./pipes/platforms.pipe";
import {SafePipe} from "./pipes/safe.pipe";
import {ShortNumberPipe} from "./pipes/short-number.pipe";
import {TimerPipe} from "./pipes/timer.pipe";
import {TimerClassPipe} from "./pipes/timer-class.pipe";
import {UrlifyPipe} from "./pipes/urlify.pipe";
import {SkeletonPageComponent} from "./components/skeleton-cards/skeleton-page/skeleton-page.component";
import {SkeletonBannerComponent} from "./components/skeleton-cards/skeleton-banner/skeleton-banner.component";
import {SkeletonCommentsComponent} from "./components/skeleton-cards/skeleton-comments/skeleton-comments.component";
import {SkeletonGamesComponent} from "./components/skeleton-cards/skeleton-games/skeleton-games.component";
import {SkeletonMessagesComponent} from "./components/skeleton-cards/skeleton-messages/skeleton-messages.component";
import {SkeletonPostListComponent} from "./components/skeleton-cards/skeleton-post-list/skeleton-post-list.component";
import {SkeletonStageComponent} from "./components/skeleton-cards/skeleton-stage/skeleton-stage.component";
import {SkeletonTableComponent} from "./components/skeleton-cards/skeleton-table/skeleton-table.component";
import {
  SkeletonPublishersListComponent
} from "./components/skeleton-cards/skeleton-publishers-list/skeleton-publishers-list.component";
import {SkeletonUserListComponent} from "./components/skeleton-cards/skeleton-user-list/skeleton-user-list.component";
import {SkeletonTabsDataComponent} from "./components/skeleton-cards/skeleton-tabs-data/skeleton-tabs-data.component";
import {ModalComponent} from "./components/modal/modal.component";
import {AuthSigninComponent} from "../../pages/authentication/auth-signin/auth-signin.component";
import {AuthSignupComponent} from "../../pages/authentication/auth-signup/auth-signup.component";
import {CustomToggleSwitchComponent} from "./components/custom-toggle-switch/custom-toggle-switch.component";
import {CommentItemComponent} from "./components/comment-item/comment-item.component";
import {CommentsWidgetComponent} from "./widget/comments-widget/comments-widget.component";
import {HeaderComponent} from "./components/header/header.component";
import {GameItemComponent} from "./components/game-item/game-item.component";
import {PostItemComponent} from "./components/post-item/post-item.component";
import {ScrollableWallComponent} from "./components/scrollable-wall/scrollable-wall.component";
import {ActiveUserItemComponent} from "./components/active-user-item/active-user-item.component";
import {SmallFeedItemComponent} from "./components/small-feed-item/small-feed-item.component";
import {SecondaryButtonComponent} from "./components/secondary-button/secondary-button.component";



import {UserSmallListItemComponent} from "./components/user-small-list-item/user-small-list-item.component";
import {TournamentPostComponent} from "./components/post-item/tournament-post/tournament-post.component";
import {PrizeListComponent} from "./components/prize-list/prize-list.component";
import {SponsorsListComponent} from "./components/sponsors-list/sponsors-list.component";
import {PostListComponent} from "./components/post-list/post-list.component";
import {SkeletonComponent} from "./components/skeleton/skeleton.component";
import {
  SkeletonNotificationsComponent
} from "./components/skeleton-cards/skeleton-notifications/skeleton-notifications.component";
import {BackTranslatePipe} from "./pipes/back-translate.pipe";
import {RouterModule} from "@angular/router";
import {SponsorItemComponent} from "./components/sponsor-item/sponsor-item.component";
import {UserImagesComponent} from "./components/user-images/user-images.component";
import {StepperComponent} from "./components/steper/stepper.component";
import {MaskLoaderComponent} from "./components/mask-loader/mask-loader.component";
import {MaskLoaderPrivateComponent} from "./components/mask-loader-private/mask-loader-private.component";
import {WriteCommentComponent} from "./components/write-comment/write-comment.component";
import {CustomDropdownComponent} from "./components/custom-dropdown/custom-dropdown.component";
import {ImageWrapperComponent} from "./components/image-wrapper/image-wrapper.component";
import {UserImagesInlineGroupComponent} from "./components/user-images-inline-group/user-images-inline-group.component";
import {ShareComponent} from "./components/functional-buttons/share/share.component";
import {FileUploaderComponent} from "./components/file-uploader/file-uploader.component";
import {ImagecropperComponent} from "./components/image-cropper/image-cropper.component";
import {MosaicImagesComponent} from "./components/mosaic-images/mosaic-images.component";
import {UserService} from "../../services/user.service";
import {GoogleAdsItemComponent} from "./components/google-ads-item/google-ads-item.component";
import {WallService} from "../../services/wall.service";
import {PushNotificationService} from "../../services/push-notification.service";
import {AuthenticationService} from "../../services/authentication.service";
import {PopupHelperService} from "../../services/popup-helper.service";
import {MeService} from "../../services/me.service";
import {RoomService} from "../../services/room.service";
import {CommentsService} from "../../services/comments.service";
import {MessagesWidgetComponent} from "./widget/messages-widget/messages-widget.component";
import {NotificationItemComponent} from "./components/notification-item/notification-item.component";
import {NotificationsWidgetComponent} from "./widget/notifications-widget/notifications-widget.component";
import {CustomSearchInputComponent} from "./components/custom-search-input/custom-search-input.component";
import {SliderSideMenuComponent} from "./components/slider-side-menu/slider-side-menu.component";
import {ChatRoomItemComponent} from "./components/chat-room-item/chat-room-item.component";
import {ChatWidgetComponent} from "./components/chat-widget/chat-widget.component";
import {ChatComponent} from "./components/chat/chat.component";
import {ChatInputComponent} from "./components/chat-input/chat-input.component";
import {MessageItemComponent} from "./components/message-item/message-item.component";
import {MessageSendingImageComponent} from "./components/message-sending-image/message-sending-image.component";
import {FileUploadService} from "../../services/file-upload.service";
import {ShareOpenedComponent} from "./components/share-opened/share-opened.component";
import {VoteChatItemComponent} from "./components/vote-chat-item/vote-chat-item.component";
import {SkeletonVoteComponent} from "./components/skeleton-cards/skeleton-vote/skeleton-vote.component";
import {PinMessageItemComponent} from "./components/pin-message-item/pin-message-item.component";
import {
  SendTempTeamReqComponent
} from "./components/functional-buttons/send-temp-team-req/send-temp-team-req.component";
import {ChallengeService} from "../../services/challenge.service";
import {StagesSectionComponent} from "./components/stages-and-rounds/stage-item/stages-section.component";
import {RoundsSectionComponent} from "./components/stages-and-rounds/round-item/rounds-section.component";
import {CustomAccordionComponent} from "./components/custom-accordion/custom-accordion.component";

import {GameBannerComponent} from "./components/banners/game/game-banner.component";
import {
  TeamInvitationWidgetItemComponent
} from "./widget/event-bar/items/team-invitation-widget-item/team-invitation-widget-item.component";
import {TemporaryTeamSectionComponent} from "./components/temporary-team-section/temporary-team-section.component";
import {UsersSearchWidgetComponent} from "./components/users-search-widget/users-search-widget.component";
import {FriendsListComponent} from "./components/users-search-widget/friends/friends-list.component";
import {AllUsersListComponent} from "./components/users-search-widget/all/all-users-list.component";
import {ProfileCardColumnComponent} from "./components/profile-card-column/profile-card-column.component";
import {SendGameInviteComponent} from "./components/functional-buttons/send-game-invite/send-game-invite.component";
import {OpenRoomComponent} from "./components/functional-buttons/open-room/open-room.component";
import {FeedBackComponent} from "./components/feed-back/feed-back.component";
import {QualityService} from "../../services/quality.service";
import {ShowAddComponent} from "./components/show-add/show-add.component";
import {ProfileCardComponent} from './components/profile-card/profile-card.component';
import {UserGameItemComponent} from "./components/user-game-item/user-game-item.component";
import {AutogrowInputDirective} from "./directives/autogrow-input.directive";

import {AdsenseModule} from 'ng2-adsense';
import {
  GenerateAvatarComponent
} from "./widget/onboarding/special-onboarding/main/generate-avatar/generate-avatar.component";
import {PostComponent} from "../../pages/post/post.component";
import {SkeletonTasksComponent} from './components/skeleton-cards/skeleton-tasks/skeleton-tasks.component';
import {SendTeamReqComponent} from "./components/functional-buttons/send-team-req/send-team-req.component";
import {TeamsService} from "../../services/teams.service";
import {TeamListItemComponent} from "./components/team-list-item/team-list-item.component";
import {ScoreSumPipe} from "./pipes/score-sum.pipe";
import {NewPlayersListComponent} from "./components/new-player-list/new-players-list.component";
import {NewTeamsListComponent} from "./components/new-team-list/new-teams-list.component";
import { SendJudgeInviteComponent } from './components/functional-buttons/send-judge-invite/send-judge-invite.component';
import { FolowersListComponent } from './components/users-search-widget/folowers/folowers-list.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import {
  SkeletonUserListColumnComponent
} from './components/skeleton-cards/skeleton-user-list-column/skeleton-user-list-column.component';
import {
  SkeletonChallengListPublisherComponent
} from './components/skeleton-cards/skeleton-challeng-list-publisher/skeleton-challeng-list-publisher.component';
import {CookieService} from "ngx-cookie-service";
import {
  TournamentItemComponent
} from "./components/tournament-item-component/tournament-item.component";
import {ProgressLevelComponent} from "./components/trust-level/progress-level.component";
import {ReviewListComponent} from "./components/review-list/review-list.component";
import {
  SkeletonTournamentListComponent
} from "./components/skeleton-cards/skeleton-tournamnt-list/skeleton-tournament-list.component";
import {
  SkeletonChallengListUserComponent
} from "./components/skeleton-cards/skeleton-challeng-list-user/skeleton-challeng-list-user.component";
import { InputFormComponent } from "./components/input-form/input-form.component";
import {TicketComponent} from "./components/ticket/tournament/ticket.component";
import {TournamentService} from "../../services/tournament.service";
import { StageService } from '../../services/stage.service';
import { NavigateAccountsComponent } from './components/navigate-accounts/navigate-accounts.component';
import { PublisherService } from '../../services/publisher-service';
import {
  SkeletonUniversalComponent
} from './components/skeleton-cards/skeleton-universal/skeleton-universal.component';
import {BracketsComponent} from "./components/brackets/brackets.component";
import {BracketChallengeComponent} from "./components/brackets/bracket-challenge/bracket-challenge.component";
import {ProductListItemComponent} from "./components/product-list-item/product-list-item.component";
import {OfferBannerComponent} from "./components/banners/offer-banner/offer-banner.component";
import {DetailsPopupComponent} from "./components/details-popup/details-popup.component";
import {
  DetailsPopupChallengeComponent
} from "./components/details-popup/details-popup-challenge/details-popup-challenge.component";
import {DetailsPopupVideoComponent} from "./components/details-popup/details-popup-video/details-popup-video.component";
import {MainInfoSectionComponent} from "./components/main-info/main-info-section.component";
import { runningLineComponent } from './components/running-line/running-line.component';
import { FooterForNewVersionComponent } from './components/footer_For_new_Version/footer_For_New_Version.component';
import {DetailsPopupMatchComponent} from "./components/details-popup/details-popup-match/details-popup-match.component";
import {CustomInputComponent} from "./components/custom-input/custom-input.component";
import {LanguageChangerComponent} from "./components/language-changer/language-changer.component";
// import { SharedModule } from './shared.module';


// @ts-ignore
@NgModule({
  imports: [
    CommonModule,
    ImageCropperModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule, HttpClientModule,

    TranslateModule.forChild(),
    AdsenseModule.forRoot({
      adClient: 'ca-pub-2616911764893650',
      adSlot: 4195203130,
    })
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LanguageChangerComponent,
    GenerateAvatarComponent,
    AdsenseModule,
    DetailsPopupComponent,
    DetailsPopupMatchComponent,
    DetailsPopupVideoComponent,
    BracketsComponent,
    BracketChallengeComponent,
    TranslateModule,
    StagesSectionComponent,
    GoogleAdsItemComponent,
    RoundsSectionComponent,
    CustomAccordionComponent,
    ShareOpenedComponent,
    TicketComponent,
    LabelComponent,
    InputFormComponent,
    CustomCheckboxComponent,
    CustomModalComponent,
    PrimaryButtonComponent,
    ValidationErrorComponent,
    SecondaryButtonComponent,
    MessagesWidgetComponent,
    NotificationItemComponent,
    NotificationsWidgetComponent,
    PostComponent,
    SkeletonPageComponent,
    SkeletonBannerComponent,
    SkeletonCommentsComponent,
    SkeletonGamesComponent,
    SkeletonChallengListPublisherComponent,
    SkeletonChallengListUserComponent,
    SkeletonMessagesComponent,
    SkeletonPostListComponent,
    SkeletonStageComponent,
    SkeletonTableComponent,
    SkeletonPublishersListComponent,
    SkeletonUserListComponent,
    SkeletonUserListColumnComponent,
    SkeletonTabsDataComponent,
    SkeletonTasksComponent,
    SkeletonComponent,
    SkeletonNotificationsComponent,
    ModalComponent,
    AuthSigninComponent,
    AuthSignupComponent,
    CustomToggleSwitchComponent,
    CommentItemComponent,
    CommentsWidgetComponent,
    HeaderComponent,
    GameItemComponent,
    PostItemComponent,
    ScrollableWallComponent,
    ActiveUserItemComponent,
    PostListComponent,
    UserImagesComponent,
    FileUploaderComponent,
    ImagecropperComponent,
    MosaicImagesComponent,
    StepperComponent,
    MaskLoaderComponent,
    MaskLoaderPrivateComponent,
    WriteCommentComponent,
    ShareComponent,
    CustomDropdownComponent,
    DropdownComponent,
    UserSmallListItemComponent,
    TournamentPostComponent,
    PrizeListComponent,
    SponsorsListComponent,
    SponsorItemComponent,
    CurrencyPipe,
    KeyobjectPipe,
    PlatformsPipe,
    SafePipe,
    ShortNumberPipe,
    BackTranslatePipe,
    CustomSearchInputComponent,
    SliderSideMenuComponent,
    ChatWidgetComponent,
    ChatComponent,
    ChatInputComponent,
    ChatRoomItemComponent,
    MessageItemComponent,
    MessageSendingImageComponent,
    TimerPipe,
    TimerClassPipe,
    ScoreSumPipe,
    UrlifyPipe,
    ImageWrapperComponent,
    UserImagesInlineGroupComponent,
    ProductListItemComponent,
    OfferBannerComponent,
    NewPlayersListComponent,
    NewTeamsListComponent,
    MainInfoSectionComponent,
    VoteChatItemComponent,
    SkeletonVoteComponent,
    SkeletonUniversalComponent,
    PinMessageItemComponent,
    SendTempTeamReqComponent,
    GameBannerComponent,
    TeamInvitationWidgetItemComponent,
    UsersSearchWidgetComponent,
    FriendsListComponent,
    TournamentItemComponent,
    CustomInputComponent,
    FolowersListComponent,
    AllUsersListComponent,
    ProfileCardColumnComponent,
    SendGameInviteComponent,
    SendJudgeInviteComponent,
    OpenRoomComponent,
    FeedBackComponent,
    ShowAddComponent,
    OpenRoomComponent,
    UserGameItemComponent,
    ProgressLevelComponent,
    SendTeamReqComponent,
    ReviewListComponent,
    TeamListItemComponent,
    ProfileCardComponent,
    DetailsPopupChallengeComponent,
    SkeletonTournamentListComponent,
    NavigateAccountsComponent,
    runningLineComponent,
    FooterForNewVersionComponent
  ],
  declarations: [
    ReviewListComponent,
    CustomInputComponent,
    StagesSectionComponent,
    GenerateAvatarComponent,
    ShowAddComponent,
    RoundsSectionComponent,
    LabelComponent,
    InputFormComponent,
    LanguageChangerComponent,
    SliderSideMenuComponent,
    ChatRoomItemComponent,
    DetailsPopupVideoComponent,
    MainInfoSectionComponent,
    MessageSendingImageComponent,
    MessageItemComponent,
    ChatWidgetComponent,
    ChatComponent,
    TicketComponent,
    ChatInputComponent,
    OfferBannerComponent,
    BracketsComponent,
    BracketChallengeComponent,
    ProductListItemComponent,
    CustomCheckboxComponent,
    GoogleAdsItemComponent,
    ProgressLevelComponent,
    CustomModalComponent,
    PrimaryButtonComponent,
    ValidationErrorComponent,
    SecondaryButtonComponent,
    MessagesWidgetComponent,
    NotificationItemComponent,
    NotificationsWidgetComponent,
    TournamentItemComponent,
    CustomAccordionComponent,
    DetailsPopupComponent,
    DetailsPopupMatchComponent,
    NewPlayersListComponent,
    NewTeamsListComponent,
    FileUploaderComponent,
    ImagecropperComponent,
    MosaicImagesComponent,
    SkeletonPageComponent,
    SkeletonBannerComponent,
    SkeletonCommentsComponent,
    SkeletonGamesComponent,
    SkeletonChallengListPublisherComponent,
    SkeletonChallengListUserComponent,
    SkeletonMessagesComponent,
    SkeletonPostListComponent,
    SkeletonStageComponent,
    SkeletonTableComponent,
    SkeletonPublishersListComponent,
    SkeletonUserListComponent,
    SkeletonUserListColumnComponent,
    SkeletonTabsDataComponent,
    SkeletonTasksComponent,
    SkeletonComponent,
    SkeletonNotificationsComponent,
    ModalComponent,
    AuthSigninComponent,
    AuthSignupComponent,
    CustomToggleSwitchComponent,
    CustomSearchInputComponent,
    CommentItemComponent,
    CommentsWidgetComponent,
    HeaderComponent,
    GameItemComponent,
    ScrollableWallComponent,
    ActiveUserItemComponent,
    SmallFeedItemComponent,
    PostListComponent,
    ShareOpenedComponent,
    UserImagesComponent,
    StepperComponent,
    MaskLoaderComponent,
    MaskLoaderPrivateComponent,
    WriteCommentComponent,
    CustomDropdownComponent,
    DropdownComponent,
    UserSmallListItemComponent,
    TournamentPostComponent,
    PrizeListComponent,
    SponsorsListComponent,
    SponsorItemComponent,
    ImageWrapperComponent,
    UserImagesInlineGroupComponent,
    ShareComponent,
    PostItemComponent,
    PostComponent,
    CurrencyPipe,
    KeyobjectPipe,
    PlatformsPipe,
    BackTranslatePipe,
    SafePipe,
    ShortNumberPipe,
    TimerPipe,
    TimerClassPipe,
    ScoreSumPipe,
    UrlifyPipe,
    VoteChatItemComponent,
    SkeletonVoteComponent,
    SkeletonUniversalComponent,
    PinMessageItemComponent,
    SendTempTeamReqComponent,
    GameBannerComponent,
    TeamInvitationWidgetItemComponent,
    UsersSearchWidgetComponent,
    FriendsListComponent,
    FolowersListComponent,
    AllUsersListComponent,
    ProfileCardColumnComponent,
    SendGameInviteComponent,
    SendJudgeInviteComponent,
    OpenRoomComponent,
    FeedBackComponent,
    ProfileCardComponent,
    UserGameItemComponent,
    AutogrowInputDirective,
    SendTeamReqComponent,
    TeamListItemComponent,
    SkeletonTournamentListComponent,
    NavigateAccountsComponent,
    DetailsPopupChallengeComponent,
    runningLineComponent,
    FooterForNewVersionComponent,
  ],
  providers: [
    CurrencyPipe,
    KeyobjectPipe,
    PlatformsPipe,
    BackTranslatePipe,
    SafePipe,
    ShortNumberPipe,
    TimerPipe,
    TimerClassPipe,
    UrlifyPipe,
    PushNotificationService,
    AuthenticationService,
    PopupHelperService,
    MeService,
    CommentsService,
    RoomService,
    UserService,
    WallService,
    FileUploadService,
    ChallengeService,
    ScoreSumPipe,
    QualityService,
    CookieService,
    TeamsService,
    TournamentService,
    StageService,
    PublisherService
  ]
})

export class SharedGuestModule {
}

// required for AOT compilation

