
export const customSetTimeout = (callback:any, timer:any = 0) => {
  if(typeof window !== 'undefined'){
    return setTimeout(callback, timer)
  }
  return undefined;
}

export const customSetInterval = (callback:any, timer:any) => {
  if(typeof window !== 'undefined'){
    return setInterval(callback, timer)
  }
  return undefined;
}

