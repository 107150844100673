import {Injectable} from '@angular/core';
import Swal from 'sweetalert2';
import {EventManagerService} from './event-manager.service';
import {TranslateService} from "@ngx-translate/core";


@Injectable()

export class PopupHelperService {
  translatedWords:any = {}
  constructor(public eventManager: EventManagerService,
              public translateService: TranslateService){
    //PopupHelper.eventManager = this.eventManager
    let translatableArrays = ['good_job','success','request_submited', 'done','you_clicked','error','something_went_wrong','ok', 'add_password', 'You dont have password']
    this.translateService.get(translatableArrays).subscribe((res) => {
      for(let key in translatableArrays){
        this.translatedWords[translatableArrays[key]] = res[translatableArrays[key]]
      }
    });
  }

//060 276 000

  confirmationPopup(id:string,cb:any) {
    Swal.fire({
      title: this.translatedWords['good_job'],
      text: this.translatedWords['you_clicked'],
      icon: "success",
      showCloseButton: true,
      customClass:{container:"bg-000"},
      showCancelButton: true,
    }).then((willDelete:any) => {

      if (willDelete.dismiss) {
        Swal.fire('', 'Your imaginary file is safe!', 'error');
      } else {
        // cb.bind(this.eventManager)(id);
      }
    });
  }


  printSuccess(info?:any) {
    const popupSettings:any = {
      customClass: {
        popup: "bg-000  w-20",
        confirmButton: "bg-color-1AF873-imp",
        htmlContainer: "color-A5A5A5 fz-14",
        title:"color-white"
      },
      title: this.translatedWords['success'],
      icon: 'success',
      text: info?.text?this.translateService.instant(info.text): this.translatedWords['request_submited'],
      confirmButtonText: this.translatedWords['done'],
      iconColor: '#1AF873',
      timer: 1500
    }

    Swal.fire(popupSettings);
  }

  printError(info?:any, cb?:any, options:any = {}) {
    let text  = ''
    if(info?.text && options?.addMessageValue && options?.addMessageValue1){
      text =  this.translateService.instant(info.text.toString()).replaceAll('{{addMessageValue}}', this.translateService.instant(options.addMessageValue.toString())).replaceAll('{{addMessageValue1}}', this.translateService.instant(options.addMessageValue1.toString()))
    }else if(info?.text && options?.addMessageValue){
      text =  this.translateService.instant(info.text.toString()).replaceAll('{{addMessageValue}}', this.translateService.instant(options?.addMessageValue.toString()))
    }else if(info?.text && options?.addMessageValue1){
      text =  this.translateService.instant(info.text.toString()).replaceAll('{{addMessageValue1}}', this.translateService.instant(options?.addMessageValue1.toString()))
    }else if(info?.text) {
      text = this.translateService.instant(info?.text.toString())
    }else {
      text = this.translatedWords['something_went_wrong']
    }
    let popupSettings:any = {
      customClass: {
        popup: "bg-000  w-20",
        confirmButton: "pink-button-imp",
        htmlContainer: "color-white fz-14",
        title:"color-pink",
        icon:"swal2-error-icon",
        customClass: {
          container: 'my-swal'
        }
      },
      html: info.html,
      title: info?.title || this.translatedWords['error'],
      icon: 'error',
      text: text,
      confirmButtonText: info?.confirmButtonText?this.translateService.instant(info.confirmButtonText):this.translatedWords['ok'],
       iconColor: '#1AF873',
    }
    popupSettings = {...popupSettings}
    Swal.fire(popupSettings).then((result:any) => {
      if(cb){
        cb(result)
      }

    });
  }




  printQuestion(popupSettings:any, id:string | undefined, cbConfirmed:any, context:any, cbDismiss?:any) {
    if(popupSettings.customClass && !popupSettings.customClass.cancelButton){
      popupSettings.customClass.cancelButton = popupSettings.customClass.confirmButton
    }
    Swal.fire({...{cancelButtonText: this.translateService.instant('no'), showCancelButton: true}, ...popupSettings}).then((willDelete:any) => {
      if (willDelete.isConfirmed) {
        cbConfirmed.bind(context)(id);
      }else if(willDelete.dismiss && cbDismiss){
        cbDismiss.bind(context)(id);
      }
    })
  }


  test(popupSettings:any){
    Swal.fire(popupSettings)
  }


}
