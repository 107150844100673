import {Pipe, PipeTransform} from '@angular/core';
import {appConstants} from "../../../constants";

@Pipe({
  name: 'currency'
})
export class CurrencyPipe implements PipeTransform {

  transform(value: any, currencyCode:string, ...args: any[]): any {
    let icons = '';
    if(value !== null && value !== undefined){
      value = parseFloat(value)
      switch (currencyCode){
        // case appConstants.Currencies.EUR:
        //     return  "€ " + value;
        case appConstants.Currencies.AMD:
          return parseInt(value.toFixed(2)) + ' Դ';
        case appConstants.Currencies.USD:
          return  "$ " + value.toFixed(2);
        // case appConstants.Currencies.GEL:
        //   return  "₾ " + value;
        case appConstants.Currencies.RUB:
          return  parseInt(value.toFixed(2)) + " ₽";
        // case appConstants.Currencies.UAH:
        //   return  value + " ₴";
        //
        // case appConstants.Currencies.IRR:
        //   return  "\t﷼" + value;
        default:
          return  value.toFixed(2);
      }
    }
    return 'INVALID'
  }

}
