

import { Injectable } from '@angular/core';
import {HttpService} from './http.service';
import {Observable} from 'rxjs';
import {environment} from "../../environments/environment";
@Injectable()
export class PushNotificationService {

    private saveTokenUrl= '/push/save-token';
    ////

    constructor(private networkHelper: HttpService) { }

    saveToken(token: any): Observable<any> {
        return this.networkHelper.post(environment.apiURL + this.saveTokenUrl, {token});
    }

}
