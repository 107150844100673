import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from "../../../environments/environment";
import {HttpService} from "../http.service";
@Injectable()
export class PublisherSponsorService {

    private getSettingsUrl= '/sponsor/list';
    private addSettingUrl = '/sponsor';
    private getSettingInfoUrl = '/sponsor/:id';
    private searchUrl = '/sponsor/search';
    private copyUrl = '/sponsor/copy/:id';
    private seenAdUrl = '/sponsor/seen/:id';
    ////

    constructor(private networkHelper: HttpService,
                public http: HttpService) { }

    getItems(pagination:any, filter:any): Observable<any> {
        return this.networkHelper.get(environment.apiURL + this.getSettingsUrl, {...pagination, ...filter});
    }

    add(data:any): Observable<any> {
        return this.networkHelper.post(environment.apiURL +
            this.addSettingUrl, data);
    }

    delete(id:any): Observable<any> {
        return this.networkHelper.del(environment.apiURL +
            this.getSettingInfoUrl.replace(':id', id));
    }

    getById(id:any): Observable<any> {
        return this.networkHelper.get(environment.apiURL +
            this.getSettingInfoUrl.replace(':id', id));
    }

    update(id:any, data:any): Observable<any> {
        return this.networkHelper.put(environment.apiURL +
            this.getSettingInfoUrl.replace(':id',id), data);
    }
    seenAd(id:any, data:any): Observable<any> {
        return this.networkHelper.put(environment.apiURL +
            this.seenAdUrl.replace(':id',id), data);
    }

    search(key:any): Observable<any> {
        return this.networkHelper.get(environment.apiURL + this.searchUrl +'?key=' + key);
    }

    copy(id:any): Observable<any> {
        return this.networkHelper.post(environment.apiURL + this.copyUrl.replace(':id',id),{});
    }
}
