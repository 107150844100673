import {AfterViewInit, Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {SCREEN_SIZE} from "./screen-sizes";
import {ResizeService} from "../../../../services/resize.service";
import {isPlatformBrowser} from "@angular/common";
import {WindowRef} from "../../../../helpers/windowRef.service";
import {customSetTimeout} from "../../../../helpers/overrided-timers";

@Component({
  selector: 'app-size-detector',
  templateUrl: './size-detector.component.html',
  styleUrls: ['./size-detector.component.scss']
})
export class SizeDetectorComponent implements OnInit, AfterViewInit {
  prefix = 'is-';

  public sizes = [
    {
      id: SCREEN_SIZE.XS, name: 'xs', css: `d-block d-sm-none`
    },
    {
      id: SCREEN_SIZE.SM, name: 'sm', css: `d-none d-sm-block d-md-none`
    },
    {
      id: SCREEN_SIZE.MD, name: 'md', css: `d-none d-md-block d-lg-none`
    },
    {
      id: SCREEN_SIZE.LG, name: 'lg', css: `d-none d-lg-block d-xl-none`
    },
    {
      id: SCREEN_SIZE.XL, name: 'xl', css: `d-none d-xl-block`
    },
  ];

  constructor(private elementRef: ElementRef, private resizeSvc: ResizeService,
              @Inject(PLATFORM_ID) private platformId: any,
              private windowRef: WindowRef) {

  }
  ngOnInit(): void {
  }

  @HostListener("window:resize", [])
  private onResize() {
    this.detectScreenSize();
  }

  ngAfterViewInit() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    if(isPlatformBrowser(this.platformId)) {
      const currentSize = this.sizes.find(x => {
        // get the HTML element
        const el = this.elementRef.nativeElement.querySelector(`.${this.prefix}${x.id}`);

        // check its display property value
        const isVisible = this.windowRef.nativeWindow.getComputedStyle(el).display != 'none';
        return isVisible;
      });
      customSetTimeout(() => {
        if (currentSize)
          this.resizeSvc.onResize(currentSize.id);
      }, 100)
    }
  }
}
