import {NgModule} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LeftSideComponent} from './components/left-side/left-side.component';
import {LeftSideMenuComponent} from './components/left-side-menu/left-side-menu.component';
import {RouterModule} from '@angular/router';
import {PageTitleComponent} from './components/page-title/page-title.component';
import {CustomListComponent} from './components/custom-list/custom-list.component';
import {ActiveUsersWidgetComponent} from './widget/active-users-widget/active-users-widget.component';
import {AddFriendComponent} from './components/functional-buttons/add-friend/add-friend.component';
import {RemoveFriendComponent} from './components/functional-buttons/remove-friend/remove-friend.component';
import {DeleteFriendReqComponent} from './components/functional-buttons/delete-friend-req/delete-friend-req.component';
import {PlayersSectionComponent} from "./components/players/players-section.component";
import {DefineResultsComponent} from "./components/stage-management-system/define-results/define-results.component";
import {WaitingActionsComponent} from "./components/stage-management-system/waiting-actions/waiting-actions.component";
import {UploadOrCaptureImageComponent} from "./components/stage-management-system/upload-or-capture-image/upload-or-capture-image.component";
import {TeamCreateOrJoinComponent} from "./components/stage-management-system/team-create-or-join/team-create-or-join.component";
import {
  ShowCustomAddComponent
} from "./components/stage-management-system/show-add/show-add.component";
import {ReportResultsComponent} from "./components/stage-management-system/report-results/report-results.component";
import {ReadyToPlayComponent} from "./components/stage-management-system/read-to-play/ready-to-play.component";
import {PutLiveUrlComponent} from "./components/stage-management-system/put-live-url/put-live-url.component";
import {InviteTeamMembersComponent} from "./components/stage-management-system/invite-team-members/invite-team-members.component";
import {EnterRequiredFieldsComponent} from "./components/stage-management-system/enter-required-fields/enter-required-fields.component";
import {StageManagementSystemComponent} from "./components/stage-management-system/stage-management-system.component";
import {ReportProblemsComponent} from "./components/stage-management-system/report-problems/report-problems.component";
import {DisputeResultsComponent} from "./components/stage-management-system/dispute-results/dispute-results.component";
import {MessageSendingImageComponent} from './components/message-sending-image/message-sending-image.component';
import {TableListComponent} from './components/table-list/table-list.component';
import {ParticipateService} from "../../services/participate.service";
import {ActiveTournamentsCardComponent} from './components/active-tournaments-card/active-tournaments-card.component';
import {PaymentPackagesComponent} from './components/payment-packages/payment-packages.component';
import {PackageService} from "../../services/package.service";
import {OnlineUsersListComponent} from "./components/online-users-list/online-users-list.component";
import {SendTicketsComponent} from "./components/send-tickets/send-tickets.component";
import {EmailVerificationModalComponent} from "./components/email-verification/email-verification-modal.component";
import {PhoneVerificationModalComponent} from "./components/phone-verification/phone-verification-modal.component";

import {ConvertBalanceComponent} from "./components/convert-balance/convert-balance.component";
import {TopupService} from "../../services/topup.service";
import {TopupComponent} from "../../pages/my-profile/topup/topup.component";
import {SharedGuestModule} from "./shared-guest.module";
import {TelegramNotifyComponent} from "./components/telegtam-notification/telegram-notify.component";
import {GameAccountService} from "../../services/game-account.service";
import {VoteFormComponent} from "./components/vote-form/vote-form.component";
import {VoteService} from "../../services/vote.service";
import {TemporaryTeamService} from "../../services/temporary-team.service";
import {ChatRoomOptionsModalComponent} from "./components/chat-room-options-modal/chat-room-options-modal.component";

import {MyTournamentChallengesComponent} from "./components/my-tournament-challenges/my-tournament-challenges.component";

import {GameInvitationComponent} from "./components/game-invitation/game-invitation.component";
import {
  GameInvitationVariantItemComponent
} from "./components/game-invitation/game-invitation-variant-item/game-invitation-variant-item.component";
import {SendInviteComponent} from "./components/functional-buttons/send-invite/send-invite.component";
import {InviteService} from "../../services/invite.service";
import {
  GameInvitationDetectorComponent
} from "./components/game-invitation/game-invitation-detector/game-invitation-detector.component";
import {ChallengeTemplateService} from "../../services/challenge-template.service";
import {CrowingProcessService} from "../../services/crowing-process.service";
import {
  GameVariantPendingPlayersComponent
} from "./components/game-invitation/game-variant-pending-plyers/game-variant-pending-players.component";
import {GamePlayersListComponent} from "./components/game-players-list/game-players-list.component";
import {BattleGameItemComponent} from "./components/game-invitation/battle-game-item/battle-game-item.component";
import {
  ChallengeParticipateWidgetItemComponent
} from "./widget/event-bar/items/challenge-participate-widget-item/challenge-participate-widget-item.component";
import {
  TournamentParticipateWidgetItemComponent
} from './widget/event-bar/items/tournament-participate-widget-item/tournament-participate-widget-item.component';
import {NotifyActionItemComponent} from "./widget/notify-action-item/notify-action-item.component";
import {NotificationService} from "../../services/notification.service";
import {SmallFeedComponent} from "../../pages/wall/small-feed/small-feed.component";
import { LeaderItemComponent } from './components/leader-item/leader-item.component';

import {UserService} from "../../services/user.service";
import {AccountTaskService} from "../../services/account-task.service";
import {OpenaiService} from "../../services/openai.service";
import {PlayerCardComponent} from "./components/player-card/player-card.component";
import {PublisherSettingsService} from "../../services/publisher/publisher-settings-service";

import {
  JudgeSearchWidgetComponent
} from './publisher-components/publisher-tournament-item/judge-search-widget/judge-search-widget.component';
import { MiniLoaderComponent } from './components/mini-loader/mini-loader.component';

import {LocalizationService} from "../../services/localization.service";
import { LoaderComponent } from "./components/full-loader/loader.component";
import { ProductServiceItemComponent } from "./components/product-service-item/product-service-item.component";
import { UploadAnimationComponent } from "./components/upload_animation/upload_animation.component";
import { PendingChangesGuard } from 'src/app/guards/pending-changes.guard';
import {ParticipateInStageChallengesComponent} from "./components/stage-management-system/participate-in-stage-challenges/participate-in-stage-challenges.component";
import {PublisherToAccountLoaderComponent} from './components/publisher-to-account-loader/publisher-to-account-loader.component';

import { VideoAdvertisementComponent } from './components/video-advertisement/video-advertisement.component';
import {StepperSliderComponent} from "./components/stepper-slider/stepper-slider.component";
import {SingleStepComponent} from "./components/stepper-slider/single-step/single-step.component";
import { PublisherSponsorService } from '../../services/publisher/publisher-sponsor.service';
import { ToastyComponent } from './components/toasty/toasty.component';

import { ToastrModule } from 'ngx-toastr';
import {WaitingToastyComopnent} from "./components/toasty/waiting-toasty.comopnent";
import {
  PendingOrderWidgetItemComponent
} from './widget/event-bar/items/pending-order-widget-item/pending-order-widget-item.component';
import { OrderService } from '../../services/marketplace/order.service';
import {AppShellRenderDirective} from "./directives/shell-render.directive";
import {AppShellNoRenderDirective} from "./directives/shell-no-render.directive";
import {BigBannerComponent} from "./components/banners/big-banner/big-banner.component";
import { AlertWarningComponent } from './components/alert-warning/alert-warning.component';
import {InputModelComponent} from "./components/input-model/input-model.component";
import { TopupModalComponent } from './components/topup-modal/topup-modal.component';
import {TeamCreateOrJoinCardComponent} from "./components/team-create-or-join-card/team-create-or-join-card.component";
import {TemporaryTeamSectionComponent} from "./components/temporary-team-section/temporary-team-section.component";
import {BlockUserComponent} from "./components/block-user/block-user.component";
import {BlockService} from "../../services/block.service";
import {BlockedPlayersListComponent} from "./components/blocked-players-list/blocked-players-list.component";
import {TaskManagementSystemComponent} from "./components/task-management-system/task-management-system.component";
import {TaskItemComponent} from "./components/task-item/task-item.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedGuestModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right'
    })
  ],
  exports: [
    SharedGuestModule,
    OnlineUsersListComponent,
    AppShellRenderDirective,
    SmallFeedComponent,
    BlockUserComponent,
    EmailVerificationModalComponent,
    LeftSideComponent,
    PageTitleComponent,
    ConvertBalanceComponent,
    CustomListComponent,
    TopupModalComponent,
    InputModelComponent,
    PlayerCardComponent,
    AddFriendComponent,
    DeleteFriendReqComponent,
    RemoveFriendComponent,
    PlayersSectionComponent,
    MiniLoaderComponent,
    StepperSliderComponent,
    BigBannerComponent,
    SingleStepComponent,
    LoaderComponent,
    PublisherToAccountLoaderComponent,
    DefineResultsComponent,
    WaitingActionsComponent,
    UploadOrCaptureImageComponent,
    TeamCreateOrJoinComponent,
    ShowCustomAddComponent,
    ReportResultsComponent,
    ReadyToPlayComponent,
    PutLiveUrlComponent,
    InviteTeamMembersComponent,
    EnterRequiredFieldsComponent,
    StageManagementSystemComponent,
    MessageSendingImageComponent,
    TableListComponent,
    TopupComponent,
    ReportProblemsComponent,
    DisputeResultsComponent,
    PhoneVerificationModalComponent,
    SendTicketsComponent,
    TaskManagementSystemComponent,
    ActiveTournamentsCardComponent,
    LeaderItemComponent,
    PaymentPackagesComponent,
    TelegramNotifyComponent,
    VoteFormComponent,
    MyTournamentChallengesComponent,
    ChatRoomOptionsModalComponent,
    ChatRoomOptionsModalComponent,
    GameInvitationComponent,
    ProductServiceItemComponent,
    UploadAnimationComponent,
    GameInvitationVariantItemComponent,
    SendInviteComponent,
    GameInvitationDetectorComponent,
    GameVariantPendingPlayersComponent,
    GamePlayersListComponent,
    AppShellNoRenderDirective,
    BattleGameItemComponent,
    ChallengeParticipateWidgetItemComponent,
    TournamentParticipateWidgetItemComponent,
    NotifyActionItemComponent,
    JudgeSearchWidgetComponent,
    ParticipateInStageChallengesComponent,
    VideoAdvertisementComponent,
    TemporaryTeamSectionComponent,
    ToastyComponent,
    BlockedPlayersListComponent,
    WaitingToastyComopnent,
    PendingOrderWidgetItemComponent,
    TeamCreateOrJoinCardComponent,
    AlertWarningComponent,
  ],
    declarations: [
        StageManagementSystemComponent,
        MyTournamentChallengesComponent,
        StepperSliderComponent,
        BigBannerComponent,
        SingleStepComponent,
        InputModelComponent,
        LeaderItemComponent,
        PhoneVerificationModalComponent,
        PlayerCardComponent,
        EmailVerificationModalComponent,
        MiniLoaderComponent,
        LoaderComponent,
        ProductServiceItemComponent,
        PublisherToAccountLoaderComponent,
        PlayersSectionComponent,
        OnlineUsersListComponent,
        LeftSideComponent,
        LeftSideMenuComponent,
        PageTitleComponent,
        CustomListComponent,
        ActiveUsersWidgetComponent,
        AddFriendComponent,
        RemoveFriendComponent,
        DeleteFriendReqComponent,
        DefineResultsComponent,
        WaitingActionsComponent,
        UploadOrCaptureImageComponent,
        TeamCreateOrJoinComponent,
        SmallFeedComponent,
        ShowCustomAddComponent,
        ReportResultsComponent,
        ReadyToPlayComponent,
        PutLiveUrlComponent,
        InviteTeamMembersComponent,
        EnterRequiredFieldsComponent,
        TableListComponent,
        WaitingToastyComopnent,
        TopupComponent,
        AppShellNoRenderDirective,

        ReportProblemsComponent,
        DisputeResultsComponent,
        ActiveTournamentsCardComponent,
        SendTicketsComponent,
        PaymentPackagesComponent,
        TelegramNotifyComponent,
        VoteFormComponent,
        ChatRoomOptionsModalComponent,
        GameInvitationComponent,
        GameInvitationVariantItemComponent,
        SendInviteComponent,
        TaskManagementSystemComponent,
        GameInvitationDetectorComponent,
        GameVariantPendingPlayersComponent,
        GamePlayersListComponent,
        BattleGameItemComponent,
        ChallengeParticipateWidgetItemComponent,
        TournamentParticipateWidgetItemComponent,
        NotifyActionItemComponent,
        ConvertBalanceComponent,
        JudgeSearchWidgetComponent,
        UploadAnimationComponent,
        AppShellRenderDirective,
        TeamCreateOrJoinCardComponent,
        TemporaryTeamSectionComponent,
        ParticipateInStageChallengesComponent,
        VideoAdvertisementComponent,
        BlockUserComponent,
        ToastyComponent,
        BlockedPlayersListComponent,
        PendingOrderWidgetItemComponent,
        AlertWarningComponent,
        TopupModalComponent,
        TaskItemComponent
    ],
  providers: [
    ParticipateService,
    PackageService,
    TopupService,
    UserService,
    BlockService,
    GameAccountService,
    VoteService,
    TemporaryTeamService,
    InviteService,
    ChallengeTemplateService,
    CrowingProcessService,
    OpenaiService,
    AccountTaskService,
    NotificationService,
    PublisherSettingsService,
    LocalizationService,
    PendingChangesGuard,
    PublisherSponsorService,
    DatePipe,
    OrderService

  ],
  bootstrap: [ToastyComponent]
})
export class SharedModule { }
