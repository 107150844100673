import { Injectable } from '@angular/core';
import {HttpService} from './http.service';
import {Observable} from 'rxjs';
import {environment} from "../../environments/environment";

@Injectable()
export class RoomService {

    private getListUrl= '/room/list';
    private addAccountToRoomUrl = '/room/:id/add';
    private removeAccountFromRoomUrl = '/room/:id/remove';
    private addRoomUrl = '/room/add';
    private updateRoomUrl = '/room/:id';
    private getRoomUrl = '/room/forFront/:id';
    private getRoomByParamsUrl = '/room/for/:forTable/:forId/:type';
    private openRoomUrl = '/room/open-room';
    private markAllAsReadUrl = '/room/make-as-all-seen';
    private pinMessageUrl = '/room/pin/:messageId';
    private removePinUrl = '/room/remove-pin/:messageId';
  private changeStatusUrl = '/room/change-status';

    ////

    constructor(private networkHelper: HttpService) { }

    getItems(pagination: any, filter: any): Observable<any> {
        return this.networkHelper.get(environment.apiURL + this.getListUrl, {...pagination, ...filter});
    }

    add(data: any): Observable<any> {
        return this.networkHelper.post(environment.apiURL +
            this.addRoomUrl, data);
    }

    delete(id: any): Observable<any> {
        return this.networkHelper.del(environment.apiURL +
            this.updateRoomUrl.replace(':id', id));
    }

    getById(id: any): Observable<any> {
        return this.networkHelper.get(environment.apiURL +
            this.getRoomUrl.replace(':id', id));
    }

    update(id: any, data: any): Observable<any> {
        return this.networkHelper.put(environment.apiURL +
            this.updateRoomUrl.replace(':id',id), data);
    }

    pinMessage(messageId: string){
      return this.networkHelper.post(environment.apiURL +
        this.pinMessageUrl.replace(':messageId', messageId), {});
    }
    removePin(messageId: string){
      return this.networkHelper.put(environment.apiURL +
        this.removePinUrl.replace(':messageId', messageId), {});
    }

    addAccountToRoom(id: any): Observable<any> {
        return this.networkHelper.post(environment.apiURL + this.addAccountToRoomUrl.replace(':id',id),{});
    }

    removeAccountFromRoom(id: any): Observable<any> {
        return this.networkHelper.post(environment.apiURL + this.removeAccountFromRoomUrl.replace(':id',id),{});
    }

    getByForTableAndId(forTable: any, forId: any, type: any): Observable<any> {
        return this.networkHelper.get(environment.apiURL + this.getRoomByParamsUrl
            .replace(':forTable', forTable)
            .replace(':forId', forId)
          .replace(':type', type),{});
    }

    openRoom(data: any){
      return this.networkHelper.post(environment.apiURL +
        this.openRoomUrl, data);
    }
    markAllAsRead(){
      return this.networkHelper.post(environment.apiURL + this.markAllAsReadUrl);
    }

  changeStatus(data:any){
    return this.networkHelper.put(environment.apiURL + this.changeStatusUrl, data);
  }


}
