
import { Injectable } from '@angular/core';
import {HttpService} from './http.service';
import {Observable} from 'rxjs';
import {environment} from "../../environments/environment";
@Injectable()
export class UserService {

    private getUsersUrl= '/user/list';
    private getUserByIdUrl= '/user/card-info/:userId';
    private getLangAndCurrency = '/user/get-locales';
    private getTicketPriceUrl = '/user/get-ticket-price/';
    private getOnlineUsersUrl = '/user/online';
    private getByNicknameUrl = '/user/check/{{nickname}}';
    private sendTicketsUrl = '/user/{{userId}}/sendMoney';

    private sendEmailUrl = '/user/send-email';
    private pingServerUrl = '/ping';
    private sendPhoneCodeUrl = '/user/send-phone-code';
    private confirmEmailUrl = '/user/verify-email-code';
    private confirmPhoneUrl = '/user/verify-phone-code';
    private updateOnboardingStepUrl = '/me/update-onboard/';

    constructor(private networkHelper: HttpService) { }

    getList(pagination:any, filter:any): Observable<any> {
        return this.networkHelper.get(environment.apiURL + this.getUsersUrl, {...pagination, ...filter});
    }
    ping(): Observable<any> {
      return this.networkHelper.get(environment.apiURL + this.pingServerUrl,{}, 'noToken');
    }
    getLocales(): Observable<any> {
      return this.networkHelper.get(environment.apiURL +
        this.getLangAndCurrency,undefined, undefined, true);
    }

    getTicketPrice(currency:any): Observable<any> {
      return this.networkHelper.get(environment.apiURL +
        this.getTicketPriceUrl + currency,undefined, undefined, true);
    }

    getOnlineUsers(): Observable<any> {
      return this.networkHelper.get(environment.apiURL +
        this.getOnlineUsersUrl);
    }


    getByNickname(nickname: string): Observable<any> {
      return this.networkHelper.get(environment.apiURL + this.getByNicknameUrl.replace('{{nickname}}', nickname));
    }

    sendMoney(userId: string, ticketsCount: any): Observable<any> {
      return this.networkHelper.post(environment.apiURL + this.sendTicketsUrl.replace('{{userId}}', userId), {ticketsCount});
    }

    sendEmailCode(email: string): Observable<any> {
      return this.networkHelper.post(environment.apiURL + this.sendEmailUrl, {email});
    }
    sendSmsCode(phoneNumber: string): Observable<any> {
      return this.networkHelper.post(environment.apiURL + this.sendPhoneCodeUrl, {phoneNumber});
    }

    verifySmsCode(code: any): Observable<any> {
      return this.networkHelper.post(environment.apiURL + this.confirmPhoneUrl, {code});
    }
    verifyEmailCode(code: any): Observable<any> {
      return this.networkHelper.post(environment.apiURL + this.confirmEmailUrl, {code});
    }

    updateOnboardingStep(step:string, data: any): Observable<any> {
      return this.networkHelper.put(environment.apiURL + this.updateOnboardingStepUrl + step, data);
    }

    getCardInfo(userId: any){
      return this.networkHelper.get(environment.apiURL + this.getUserByIdUrl.replace(':userId', userId), );
    }

}
