import { Injectable , EventEmitter} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpService} from "./http.service";
import {Observable} from "rxjs";

@Injectable()
export class TemporaryTeamService {
  private getListUrl = '/participateTeam/list';
  private getMyTeamUrl = '/participateTeam/my-team/:forId/:forTable';
  private updateUrl = '/participateTeam/:id'
  private sendInvitationInUserUrl = '/participate-team-invite/to-user'
  private sendInvitationInTeamUrl = '/participate-team-invite/in-team/:teamId'
  private getInviteItemsUrl = '/participate-team-invite/list'
  private cancelInviteUrl = '/participate-team-invite/cancel/:id'
  private acceptInviteUrl = '/participate-team-invite/accept/:id'
  private deleteInviteUrl = '/participate-team-invite/cancel-action/:id'
  private getMyPendingFromInvitesUrl = '/participate-team-invite/from-invites/:forId/:forTable'
  private removeInTeamUrl = '/participateTeam/remove/:teamId/:accountId'
  private makeMainPlayerUrl = '/participateTeam/make-main/:teamId/:accountId'
  private makeReservePlayerUrl = '/participateTeam/make-reserve/:teamId/:accountId'
  private autoCompileTeamUrl = '/participateTeam/go-to-auto-complete/:teamId'
  private useMyTeamUrl = '/participateTeam/use-my-team/:myTeamId'


  private smallTeamListUrl = '/participateTeam/small-list'



  constructor(private networkHelper: HttpService) { }

  getItems(pagination: any, filter: any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.getListUrl, {...pagination, ...filter});
  }

  smallTeams(forId:any, needTeams:any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.smallTeamListUrl, {forId, needTeams});
  }
  getMyTeam(forId: string, forTable: string): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.getMyTeamUrl.replace(':forId', forId).replace(':forTable', forTable));
  }

  update(id: string, data: any){
    return this.networkHelper.put(environment.apiURL + this.updateUrl.replace(':id', id), data);
  }

  sendInvitationToUser(data: any){
    return this.networkHelper.post(environment.apiURL + this.sendInvitationInUserUrl, data);
  }

  getInviteItems(pagination: any, filter: any){
    return this.networkHelper.get(environment.apiURL + this.getInviteItemsUrl, {...pagination, ...filter});
  }

  cancelInvite(inviteId: string){
    return this.networkHelper.post(environment.apiURL + this.cancelInviteUrl.replace(':id', inviteId), {});
  }
  acceptInvite(inviteId: string){
    return this.networkHelper.post(environment.apiURL + this.acceptInviteUrl.replace(':id', inviteId), {});
  }
  sendInvitationInTeam(teamId: string){
    return this.networkHelper.post(environment.apiURL + this.sendInvitationInTeamUrl.replace(':teamId', teamId), {});
  }
  getMyPendingFromInvites(forId: string, forTable: string){
    return this.networkHelper.get(environment.apiURL + this.getMyPendingFromInvitesUrl.replace(':forId', forId).replace(':forTable', forTable));
  }
  deleteInvite(inviteId: string){
    return this.networkHelper.del(environment.apiURL + this.deleteInviteUrl.replace(':id', inviteId));
  }
  removeInTeam(teamId: string, accountId: string){
    return this.networkHelper.post(environment.apiURL + this.removeInTeamUrl.replace(':teamId', teamId).replace(':accountId', accountId), {});
  }
  makeMainPlayer(teamId: string, accountId: string){
    return this.networkHelper.post(environment.apiURL + this.makeMainPlayerUrl.replace(':teamId', teamId).replace(':accountId', accountId), {});
  }
  makeReservePlayer(teamId: string, accountId: string){
    return this.networkHelper.post(environment.apiURL + this.makeReservePlayerUrl.replace(':teamId', teamId).replace(':accountId', accountId), {});
  }
  autoCompileTeam(teamId: string){
    return this.networkHelper.post(environment.apiURL + this.autoCompileTeamUrl.replace(':teamId', teamId), {});
  }
  useMyTeam(myTeamId: string, body: any = {}){
    return this.networkHelper.post(environment.apiURL + this.useMyTeamUrl.replace(':myTeamId', myTeamId), body);
  }
}
