import {HostListener, Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {SCREEN_SIZE} from "../theme/shared/components/size-detector/screen-sizes";
import {distinctUntilChanged} from "rxjs/operators";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ResizeService {

  sizeId= 0;
  onResize$(): Observable<SCREEN_SIZE> {
    return this.resizeSubject.asObservable().pipe(distinctUntilChanged());
  }

  private resizeSubject: Subject<SCREEN_SIZE>;

  constructor() {
    this.resizeSubject = new Subject();
  }

  onResize(size: SCREEN_SIZE) {
    this.sizeId = size;
    this.resizeSubject.next(size);
  }

}
